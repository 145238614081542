import { createSelector } from 'reselect';

import { IQuizSlice } from '@lib/core/quizzes/slices/quiz';
import {
  TQuizAnswer,
  TQuizAnswerTag,
  TQuizData,
  TQuizNumberOfChoices,
  TQuizPath,
  TQuizQuestion,
} from '@lib/core/quizzes/types';
import QuizUtils from '@lib/core/quizzes/utils';

/**
 * @returns the whole quiz state
 */
export const selectQuizState = (state): IQuizSlice => state.quiz || {};

/**
 * @returns loading state for quiz
 */
export const selectQuizFetching: (state) => boolean = createSelector(
  [selectQuizState],
  quiz => quiz.isLoading || false,
);

/**
 * @returns quiz API errors
 */
export const selectQuizError: (state) => string = createSelector([selectQuizState], quiz => quiz.error || '');

/**
 * @returns quiz data id is set if the quiz data is pregenerated and needs to be fetched from S3
 */
export const selectQuizDataId: (state) => string = createSelector(
  [selectQuizState],
  quiz => quiz.data?.quiz_test_data_id || '',
);

/**
 * @returns an object containing questions with their images
 */
export const selectQuizQuestionImages: (state) => Record<string, string> = createSelector(
  [selectQuizState],
  quiz => quiz.data?.questions_with_images || {},
);

/**
 * @returns the quiz data for views
 */
export const selectQuizData: (state) => TQuizData = createSelector(
  [selectQuizState],
  quiz => quiz.data?.quiz_data || {},
);

/**
 * @returns the quiz graph
 */
export const selectQuizPath: (state) => TQuizPath = createSelector(
  [selectQuizState],
  quiz => quiz.data?.quiz_path || {},
);

export const selectQuizId: (state) => string = createSelector([selectQuizState], quiz => quiz.data?.identifier || '');

/**
 * @returns the list of tags associated to the quiz answers
 */
export const selectQuizAnswerTags: (state) => TQuizAnswerTag[] = createSelector([selectQuizData], quizData => {
  const questionIds: string[] = Object.keys(quizData);
  const questions: TQuizQuestion[] = Object.values(quizData);
  const tags = {};

  questions.forEach((question: TQuizQuestion, i) => {
    const { answers } = question;

    answers.forEach((answerData: Record<string, TQuizAnswer>) => {
      const answerTags: TQuizAnswerTag[] = Object.values(answerData)[0].tags;

      if (answerTags?.length) {
        answerTags.forEach(answerTag => {
          if (!QuizUtils.parseResultQuizAnswerTags(tags).includes(answerTag)) {
            if (!tags[questionIds[i]]) {
              tags[questionIds[i]] = [].concat(answerTags);
            } else {
              tags[questionIds[i]] = tags[questionIds[i]].concat(answerTag);
            }
          }
        });
      }
    });
  });

  return QuizUtils.parseResultQuizAnswerTags(tags) || [];
});

/**
 * @returns min and max choices for each question
 */
export const selectQuizQuestionNumberOfChoices: (state) => TQuizNumberOfChoices = createSelector(
  [selectQuizState],
  quiz => quiz.data?.number_of_choices || {},
);

/**
 * @returns the question DesignSets for each question
 */
export const selectQuizQuestionDesignSets: (state) => Record<string, string> = createSelector(
  [selectQuizState],
  quiz => quiz.data?.special_design || {},
);

/**
 * @returns the ID of the first question in the quiz graph
 */
export const selectQuizFirstQuestionId: (state) => string = createSelector(
  [selectQuizPath],
  quizPath => Object.keys(quizPath).filter(questionId => quizPath[questionId].is_first_question)[0] || '',
);

/**
 * @returns list of all quiz questions
 */
export const selectQuizQuestions: (state) => TQuizQuestion[] = createSelector([selectQuizData], quizData =>
  Object.values(quizData || []),
);

/**
 * @returns list of all question ids
 */
export const selectQuizQuestionIds: (state) => string[] = createSelector([selectQuizData], quizData =>
  Object.keys(quizData || []),
);

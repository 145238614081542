import { TQuizAnswerTag } from '@lib/core/quizzes/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import {
  AVAILABLE,
  DISCONTINUED,
  HIGHNICOTINECIGARETTES,
  LOWNICOTINECIGARETTES,
  TOBEDISCONTINUED,
} from '@lib/tools/shared/pmi/retailers/consts';

/**
 * * Contexts are used to filter out specific groups of tags.
 * * Names are used to indicate a specific product or behavior, based on its context.
 */

/**
 * * Context filters
 */
const isQuizAnswerTagContextSmokingType = (tag: TQuizAnswerTag): boolean => tag.context === 'smoking-type';
export const isQuizAnswerTagContextSatisfaction = (tag: TQuizAnswerTag): boolean => tag.context === 'satisfaction';
export const isQuizAnswerTagContextMood = (tag: TQuizAnswerTag): boolean => tag.context === 'mood';
export const isQuizAnswerTagContextCharacterMatch = (tag: TQuizAnswerTag): boolean => tag.context === 'character-match';
export const isQuizAnswerTagContextBlendBase = (tag: TQuizAnswerTag): boolean => tag.context === 'blend-base';
export const isQuizAnswerTagContextReferenceProduct = (tag: TQuizAnswerTag): boolean =>
  tag.context === 'reference-product';
export const isQuizAnswerTagContextBestMatch = (tag: TQuizAnswerTag): boolean => tag.context === 'best-match';

export const isQuizAnswerTagContextFilterCharacter = (tag: TQuizAnswerTag): boolean =>
  tag.context === 'filter-character';
export const isQuizAnswerTagContextFilterProduct = (tag: TQuizAnswerTag): boolean => tag.context === 'filter-product';

export const isQuizAnswerTagContextShowPage = (tag: TQuizAnswerTag): boolean => tag.context === 'show-page';
export const isQuizAnswerTagContextProductState = (tag: TQuizAnswerTag): boolean => tag.context === 'product-state';
export const isQuizAnswerTagContextVisual = (tag: TQuizAnswerTag): boolean => tag.context === 'visual';
export const isQuizAnswerTagUserSegment = (tag: TQuizAnswerTag): boolean => tag.context === 'user-segment';
export const isQuizAnswerTagContextVisualProductType = (tag: TQuizAnswerTag): boolean =>
  tag.context === 'visual-product-type';

/**
 * * Name filters
 */
export const isQuizAnswerTagDifferentCharacter = (tag: TQuizAnswerTag): boolean => tag.name === 'different-character';
export const isQuizAnswerTagSameCharacter = (tag: TQuizAnswerTag): boolean => tag.name === 'same-character';
export const isQuizAnswerTagShowPageBestMatch = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextShowPage(tag) && tag.name === 'best-match';
export const isQuizAnswerTagShowPageBundles = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextShowPage(tag) && tag.name === 'bundles';
export const isQuizAnswerTagProductStateDiscontinued = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextProductState(tag) && tag.name === 'discontinued';
export const isQuizAnswerTagProductStateToBeDiscontinued = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextProductState(tag) && tag.name === 'to-be-discontinued';
export const isResultLimitedCharacter = (tag: TQuizAnswerTag): boolean => tag.name === 'result-limited-character';
export const isQuizAnswerTagSatisfied = (tag: TQuizAnswerTag): boolean => tag.name === 'happy';
export const isQuizAnswerTagDissatisfied = (tag: TQuizAnswerTag): boolean =>
  tag.name === 'unhappy' || tag.name === 'neutral';
export const isQuizAnswerTagLauTerea = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextSmokingType(tag) && tag.name === 'lau-terea';
export const isQuizAnswerTagLauHeets = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextSmokingType(tag) && tag.name === 'lau-heets';
export const isQuizAnswerTagLauSentia = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextSmokingType(tag) && tag.name === 'lau-sentia';
export const isQuizAnswerTagLas = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextSmokingType(tag) && tag.name?.startsWith('las');
export const isQuizAnswerTagLanu = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextSmokingType(tag) && tag.name?.startsWith('lanu');
export const isQuizAnswerTagRecommendSentia = (tag: TQuizAnswerTag): boolean =>
  isQuizAnswerTagContextVisual(tag) && tag.name === 'recommend-sentia';
export const isTranslatableTag = tagName => {
  const translatedTitle = {
    [AVAILABLE]: localeV2.quiz.availableProducts,
    [DISCONTINUED]: localeV2.quiz.discontinuedProducts,
    [HIGHNICOTINECIGARETTES]: localeV2.quiz.highNicotineCigarettes,
    [LOWNICOTINECIGARETTES]: localeV2.quiz.mediumOrLowNicotineCigarettes,
    [TOBEDISCONTINUED]: localeV2.quiz.toBeDiscontinuedProducts,
  };
  return Object.keys(translatedTitle).includes(tagName);
};

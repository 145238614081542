import cn from 'classnames';
import { Dispatch, FC, Fragment, SetStateAction, useMemo } from 'react';

import { TQuizAnswerData } from '@lib/core/quizzes/types';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment, { ILocaleText } from '@lib/tools/locale/views/LocaleFragment';
import {
  isQuizAnswerTagContextProductState,
  isQuizAnswerTagContextVisualProductType,
} from '@lib/tools/shared/pmi/quizzes/filters';
import {
  AVAILABLE,
  DISCONTINUED,
  HIGHNICOTINECIGARETTES,
  LOWNICOTINECIGARETTES,
  TOBEDISCONTINUED,
} from '@lib/tools/shared/pmi/retailers/consts';

import AnswerSectionHeader from '@components/pmi/src/organisms/Quiz/AnswerSectionHeader/AnswerSectionHeader';
import AnswerItem from '@components/pmi/src/organisms/Quiz/QDS8002/AnswerItem/AnswerItem';
import { defineLongWord } from '@components/pmi/src/utils';

interface Props {
  answers: TQuizAnswerData[];
  maxAnswersChoices: number;
  setAnswersArray: Dispatch<SetStateAction<string[]>>;
  answersArray: string[];
  handleConfirmAnswer: (answerId: string, callback: () => void) => void;
  isLight?: boolean;
  currentPrimaryColor: string;
  isIgnoreProductSeparationAddon?: boolean;
}

interface SplitAnswersArrayProps {
  filteredAnswersArray: TQuizAnswerData[];
  order: number;
  title: ILocaleText | string;
}

const translatedTitle = {
  [AVAILABLE]: localeV2.quiz.availableProducts,
  [DISCONTINUED]: localeV2.quiz.discontinuedProducts,
  [HIGHNICOTINECIGARETTES]: localeV2.quiz.highNicotineCigarettes,
  [LOWNICOTINECIGARETTES]: localeV2.quiz.mediumOrLowNicotineCigarettes,
  [TOBEDISCONTINUED]: localeV2.quiz.toBeDiscontinuedProducts,
};

const isTranslatableTag = tagName => Object.keys(translatedTitle).includes(tagName);

const AnswerSection: FC<Props> = ({
  handleConfirmAnswer,
  answers,
  maxAnswersChoices,
  answersArray,
  setAnswersArray,
  isLight = false,
  currentPrimaryColor,
  isIgnoreProductSeparationAddon,
}) => {
  const splitAnswersArray = useMemo(() => {
    return answers.reduce((acc: SplitAnswersArrayProps[], answer, index) => {
      const answerId = Object.keys(answer)[0];
      const tags = answer[answerId]?.tags || [];

      const tagName =
        tags.find(isQuizAnswerTagContextVisualProductType)?.name ||
        tags.find(isQuizAnswerTagContextProductState)?.name ||
        AVAILABLE;
      const existingGroup = acc.find(splitAnswer => splitAnswer.title === tagName);

      if (existingGroup) {
        existingGroup.filteredAnswersArray.push(answer);
      } else {
        acc.push({
          filteredAnswersArray: [answer],
          order: tagName === AVAILABLE ? 0 : index + 1,
          title: tagName,
        });
      }

      return acc;
    }, []);
  }, [answers]);

  const finalSortedAnswers: SplitAnswersArrayProps[] = useMemo(() => {
    return splitAnswersArray
      .filter(group => group?.filteredAnswersArray.length > 0)
      .map(tagGroup =>
        isTranslatableTag(tagGroup.title)
          ? {
              ...tagGroup,
              title:
                typeof tagGroup.title === 'string' ? translatedTitle[tagGroup.title.toLowerCase()] : tagGroup.title,
            }
          : tagGroup,
      )
      .sort((a, b) => a.order - b.order);
  }, [splitAnswersArray, translatedTitle]);

  const isLongWordInAnswer = answers.some(quizAnswer => defineLongWord(quizAnswer[Object.keys(quizAnswer)[0]]?.text));
  const isWideSize = answers.length <= 4 || isLongWordInAnswer;

  return (
    <div className="ta-v2-qds8002-answer-section-wrapper">
      <AnswerSectionHeader
        isLight={isLight}
        maxChoicesCount={maxAnswersChoices}
        selectedChoicesCount={answersArray?.length || 0}
      />

      {isIgnoreProductSeparationAddon ? (
        <div className="ta-v2-qds8002-answer-section">
          {answers.map(answer => {
            const answerId = Object.keys(answer)[0];
            return (
              <AnswerItem
                key={answerId}
                answerId={answerId}
                answerImage={answer[answerId].image}
                answerText={answer[answerId].text}
                answersArray={answersArray}
                currentPrimaryColor={currentPrimaryColor}
                handleConfirmAnswer={handleConfirmAnswer}
                isLight={isLight}
                isWideSize={isWideSize}
                maxAnswersChoices={maxAnswersChoices}
                setAnswersArray={setAnswersArray}
              />
            );
          })}
        </div>
      ) : (
        <>
          {finalSortedAnswers.map(({ filteredAnswersArray, title }, index) => {
            return (
              <Fragment key={index}>
                {!!filteredAnswersArray?.length && (
                  <>
                    {finalSortedAnswers?.length > 1 && (
                      <div
                        className={cn('font-ta-v2-global-caption-medium ta-v2-answer-section-header-small', {
                          isLight,
                          'margin-top-28px': index !== 0,
                          'text-uppercase': typeof title === 'string',
                        })}
                      >
                        <LocaleFragment message={title} />
                      </div>
                    )}

                    <div className="ta-v2-qds8002-answer-section">
                      {filteredAnswersArray.map(answer => {
                        const answerId = Object.keys(answer)[0];
                        return (
                          <AnswerItem
                            key={answerId}
                            answerId={answerId}
                            answerImage={answer[answerId].image}
                            answerText={answer[answerId].text}
                            answersArray={answersArray}
                            currentPrimaryColor={currentPrimaryColor}
                            handleConfirmAnswer={handleConfirmAnswer}
                            isLight={isLight}
                            isWideSize={isWideSize}
                            maxAnswersChoices={maxAnswersChoices}
                            setAnswersArray={setAnswersArray}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AnswerSection;

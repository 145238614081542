import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { commentsApiUrlCreator } from '@lib/core/comments/slices/urls';
import { IComment } from '@lib/core/comments/types';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';

export interface ICommentsSlice {
  data: IComment[] | undefined;
  isLoading: boolean;
  error: string;
  isCommentUpdating: boolean;
  isCommentCreating: boolean;
}

const initialState: ICommentsSlice = {
  data: undefined,
  error: '',
  isCommentCreating: false,
  isCommentUpdating: false,
  isLoading: false,
};

export const actionGetComments = createTypedAsyncThunk<IComment[]>('actionGetComments', async () => {
  const fetchComments = async (url: string | null, accumulated: IComment[] = []): Promise<IComment[]> => {
    if (!url) return accumulated;
    const { results, next } = await request(url);
    return fetchComments(next, [...accumulated, ...results]);
  };

  return fetchComments(commentsApiUrlCreator());
});

export const actionCreateComment = createTypedAsyncThunk(
  'actionCreateComment',
  async ({
    newComment,
    productIdentifier,
    isProducerFeedback,
  }: {
    newComment: string;
    productIdentifier: string;
    isProducerFeedback?: boolean;
  }) => {
    return await request(
      commentsApiUrlCreator(),
      { method: REQUEST_METHODS.POST },
      {
        comment: newComment,
        product: productIdentifier,
        ...(isProducerFeedback && { scope: 'PRT' }),
      },
    );
  },
);

export const actionUpdateComment = createTypedAsyncThunk(
  'actionUpdateComment',
  async ({ updateComment, identifier }: { updateComment?: string; identifier?: string }) => {
    const apiPath = `${commentsApiUrlCreator()}${identifier}/`;
    const method = { method: REQUEST_METHODS.PATCH };
    const body = { comment: updateComment };
    return request(apiPath, method, body);
  },
);

export const actionDeleteComment = createAsyncThunk(
  'actionCreateComments',
  async ({ productIdentifier }: { productIdentifier: string }) => {
    return await request(`${commentsApiUrlCreator()}${productIdentifier}}`, { method: REQUEST_METHODS.DELETE });
  },
);

export const commentsSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetComments.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(actionCreateComment.pending, state => {
      state.isCommentCreating = true;
    });
    builder.addCase(actionUpdateComment.pending, state => {
      state.isCommentUpdating = true;
    });
    builder.addCase(actionGetComments.rejected, state => {
      state.isLoading = false;
    });
    builder.addCase(actionUpdateComment.rejected, state => {
      state.isCommentUpdating = false;
    });
    builder.addCase(actionCreateComment.rejected, state => {
      state.isCommentCreating = false;
    });
    builder.addCase(actionCreateComment.fulfilled, state => {
      state.isCommentCreating = false;
    });
    builder.addCase(actionUpdateComment.fulfilled, state => {
      state.isCommentUpdating = false;
    });
    builder.addCase(actionGetComments.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addMatcher(
      isAnyOf(actionGetComments.rejected, actionCreateComment.rejected, actionUpdateComment.rejected),
      (state, action: any) => {
        if (action.payload?.errorMessage) {
          state.error = action.payload.errorMessage;
        } else if (action.error?.message) {
          state.error = action.error.message;
        }
        state.isLoading = false;
      },
    );
  },
  initialState,
  name: 'comments',
  reducers: {},
});

export default commentsSlice.reducer;

import cn from 'classnames';
import { useState } from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { TProductInstance } from '@lib/core/products/types';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { parseTereaVariants, showProductWithCoolingEssence } from '@lib/tools/shared/pmi/products';
import { isProductLevia, isProductSentia, isProductTagNicotineLevel } from '@lib/tools/shared/pmi/products/filters';
import { HEETS, TEREA } from '@lib/tools/shared/pmi/retailers/consts';
import { useDisclosure, useHeight } from '@lib/tools/views/hooks';

import CTANavigator from '@components/pmi/src/atoms/CTA/Navigator';
import HealthWarningFooterToIsrael from '@components/pmi/src/common/HealthWarningFooterToIsrael';
import HealthWarning from '@components/pmi/src/organisms/HealthWarning/HealthWarning';
import LeviaBanner from '@components/pmi/src/organisms/Results/Banner/Levia/LeviaBanner';
import TereaBanner from '@components/pmi/src/organisms/Results/Banner/Terea/TereaBanner';
import VeevBanner from '@components/pmi/src/organisms/Results/Banner/Veev/VeevBanner';
import CharacterHeader from '@components/pmi/src/organisms/Results/CharacterHeader/CharacterHeader';
import MentholDisclaimer from '@components/pmi/src/organisms/Results/MentholDisclaimer/MentholDisclaimer';
import RecommendedProduct, {
  TechnicalCaptionIcon,
} from '@components/pmi/src/organisms/Results/RecommendedProduct/RecommendedProduct';
import ResultLongDisclaimer from '@components/pmi/src/organisms/Results/ResultLongDisclaimer/ResultLongDisclaimer';
import PortfolioSection from '@components/pmi/src/templates/Modals/PortfolioSection/PortfolioSection';
import { handleEcommerceSeeAllFlavours } from '@components/pmi/src/utils';

interface Props {
  startOver: () => void;
  isBackgroundEnabled: boolean;
  resultPageBackgroundImage: string;
  gprls: TProductInstance[];
  characterHeaderName: string;
  characterHeaderImage: string;
  characterHeaderCaption: string;
  characterHeaderDescription: string;
  technicalCaptionIcon: TechnicalCaptionIcon[];
  technicalCaptionTextNumbers: number[];
  isTechnicalCaptionEnable: boolean;
  recommendedGprls: TProductInstance[];
  selectedProductCategory: string;
  isAromaNotesDisabledAddon: boolean;
  isAromaNoteSecondaryAddon: boolean;
  isAddToCartAddon: boolean;
  isEnableBuyEcommerceAddon: boolean;
  isEnableTereaBannerAddon: boolean;
  isEnableVeevBannerAddon: boolean;
  isEnableLeviaBannerAddon: boolean;
  isEssenceAromaDisabledAddon: boolean;
  isEssenceBodyDisabledAddon: boolean;
  isEssenceCoolingDisabledAddon: boolean;
  isRestrictedProductCardsAddon: boolean;
  isRestrictedResultPageAddon: boolean;
  isTereaHideEssencesAddon: boolean;
  isTobaccoExperienceHiddenAddon: boolean;
  isProductLongDescriptionHiddenAddon: boolean;
  isProductShortDescriptionHiddenAddon: boolean;
  noCharacter?: boolean;
  isVeev?: boolean;
  minQuantity: number;
  maxQuantity: number;
  isOpenIntegration: boolean;
  isShowHealthWarningFooter: boolean;
  isSentiaEnabled?: boolean;
  isMentholDisclaimerAddon: boolean;
  isHealthWarningFooterToIsraelAddon: boolean;
  isResultLongDisclaimerAddon: boolean;
  isRestrictedRecommendationAddon: boolean;
  isThreeProductsShown: boolean;
  isUserQuizTypeShortRecommender: boolean;
}

const ResultPage = ({
  isBackgroundEnabled,
  startOver,
  characterHeaderCaption,
  characterHeaderDescription,
  characterHeaderImage,
  characterHeaderName,
  recommendedGprls,
  gprls,
  resultPageBackgroundImage,
  technicalCaptionIcon,
  technicalCaptionTextNumbers,
  isTechnicalCaptionEnable,
  selectedProductCategory,
  isAromaNotesDisabledAddon,
  isAromaNoteSecondaryAddon,
  isAddToCartAddon,
  isEnableBuyEcommerceAddon,
  isEnableTereaBannerAddon,
  isEnableVeevBannerAddon,
  isEnableLeviaBannerAddon,
  isEssenceAromaDisabledAddon,
  isEssenceBodyDisabledAddon,
  isEssenceCoolingDisabledAddon,
  isRestrictedProductCardsAddon,
  isRestrictedResultPageAddon,
  isTereaHideEssencesAddon,
  isTobaccoExperienceHiddenAddon,
  noCharacter,
  isVeev,
  maxQuantity,
  minQuantity,
  isProductLongDescriptionHiddenAddon,
  isProductShortDescriptionHiddenAddon,
  isOpenIntegration,
  isShowHealthWarningFooter,
  isSentiaEnabled,
  isMentholDisclaimerAddon,
  isHealthWarningFooterToIsraelAddon,
  isResultLongDisclaimerAddon,
  isRestrictedRecommendationAddon,
  isThreeProductsShown,
  isUserQuizTypeShortRecommender,
}: Props) => {
  const [isOpen, { open, close }] = useDisclosure();
  const [footerRef, paddingForFooter] = useHeight<HTMLDivElement>();
  const { isLayoutRightToLeft } = useApp();
  const { isRetailerLocationStoreTypeEcommerce } = useRetailerLocation();
  const isLeviaBannerAvailable = recommendedGprls.some(isProductLevia);
  const isLeviaSeparateCtaCondition = recommendedGprls.some(isProductLevia) && isRetailerLocationStoreTypeEcommerce;

  let resultPageBackground = '';

  if (isRestrictedResultPageAddon && selectedProductCategory === TEREA) {
    resultPageBackground = '#51BAB0';
  } else if (isRestrictedResultPageAddon && selectedProductCategory === HEETS) {
    resultPageBackground = '#f6f4f0';
  } else {
    resultPageBackground = isBackgroundEnabled
      ? `url(${resultPageBackgroundImage}) center/cover no-repeat `
      : '#f6f4f0';
  }

  const resultPageStyles = {
    background: resultPageBackground,
    paddingBottom: paddingForFooter,
  };

  const veevResultPageStyle = {
    background: 'linear-gradient(90deg, #21164F 0%, #265B7D 100%)',
    paddingBottom: paddingForFooter,
  };

  const [filteredGprls, setFilteredGprls] = useState([]);

  const { orderedSentiaProducts, orderedTereaProducts } = parseTereaVariants(gprls);

  const isProductWithCoolingEssencePresent =
    recommendedGprls.some(gprl => showProductWithCoolingEssence(gprl)) && !isEssenceCoolingDisabledAddon;

  const handleSeeAllTerea = () => {
    open();
    setFilteredGprls(orderedTereaProducts);
  };

  const handleSeeAllSentia = () => {
    open();
    setFilteredGprls(orderedSentiaProducts);
  };

  const getFilteredCtaButton = (i: number, gprl: TProductInstance) => {
    if (isSentiaEnabled) {
      const btnText = isProductSentia(gprl) ? localeV2.common.seeAllSentia : localeV2.common.seeAllTerea;
      const handleClick = isProductSentia(gprl) ? handleSeeAllSentia : handleSeeAllTerea;

      return (
        <div className={`ta-v2-result-page-cta-group-${i === 0 ? 'terea' : 'sentia'}`}>
          <CTANavigator
            btnText={<LocaleFragment message={btnText} />}
            className="margin-top-24px text-underlined"
            direction="right"
            isDark={!isVeev}
            isLayoutRightToLeft={isLayoutRightToLeft}
            onBtnClick={isRetailerLocationStoreTypeEcommerce ? handleEcommerceSeeAllFlavours : handleClick}
          />
        </div>
      );
    }
    if (isLeviaSeparateCtaCondition) {
      const btnText = isProductLevia(gprl) ? localeV2.common.seeAllLevia : localeV2.common.seeAllTerea;

      return (
        <div className={`ta-v2-result-page-cta-group-${i === 0 ? 'terea' : 'sentia'}`}>
          <CTANavigator
            btnText={<LocaleFragment message={btnText} />}
            className="margin-top-24px text-underlined"
            direction="right"
            isDark={!isVeev}
            isLayoutRightToLeft={isLayoutRightToLeft}
            onBtnClick={() => handleEcommerceSeeAllFlavours(true)}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className={`ta-v2-result-page ${isHealthWarningFooterToIsraelAddon ? 'padding-bottom-30vh minh-101vh' : 'minh-100vh'}`}
        style={isVeev ? veevResultPageStyle : resultPageStyles}
      >
        <CharacterHeader
          caption={characterHeaderCaption}
          description={characterHeaderDescription}
          image={characterHeaderImage}
          isThreeProductsShown={isThreeProductsShown}
          isUserQuizTypeShortRecommender={isUserQuizTypeShortRecommender}
          name={characterHeaderName}
          noCharacter={noCharacter}
        />

        <div
          className={cn('ta-v2-result-page-content-box', {
            isThreeProductsShown,
          })}
        >
          <Swiper
            modules={[Pagination]}
            slidesPerView="auto"
            className={cn('swiper', {
              isThreeProductsShown,
            })}
            pagination={{
              bulletActiveClass: 'pagination-bullet-active',
              bulletClass: 'pagination-bullet',
              clickable: true,
              el: `.${'ta-v2-result-page-pagination-container'}`,
              type: 'bullets',
            }}
          >
            {recommendedGprls.map((gprl, i) => (
              <SwiperSlide
                key={i}
                className={cn('swiper-slide', {
                  isThreeProductsShown,
                  'rtl-orientation': isLayoutRightToLeft,
                })}
              >
                <RecommendedProduct
                  key={gprl.identifier}
                  gprl={gprl}
                  isAddToCartAddon={isAddToCartAddon}
                  isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
                  isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
                  isEnableBuyEcommerceAddon={isEnableBuyEcommerceAddon}
                  isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
                  isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
                  isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
                  isMentholDisclaimerAddon={isMentholDisclaimerAddon}
                  isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
                  isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
                  isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
                  isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
                  isTechnicalCaptionEnable={isTechnicalCaptionEnable}
                  isTereaHideEssencesAddon={isTereaHideEssencesAddon}
                  isThreeProductsShown={isThreeProductsShown}
                  isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
                  isVeev={isVeev}
                  maxQuantity={maxQuantity}
                  minQuantity={minQuantity}
                  nicotineLevelData={gprl.retailer_product_location_tags?.filter(isProductTagNicotineLevel)}
                  selectedProductCategory={selectedProductCategory}
                  technicalCaptionIconName={technicalCaptionIcon[i]}
                  technicalCaptionTextNumber={technicalCaptionTextNumbers[i]}
                />
                {getFilteredCtaButton(i, gprl)}
              </SwiperSlide>
            ))}
          </Swiper>

          {!isSentiaEnabled && !isLeviaSeparateCtaCondition && (
            <CTANavigator
              btnText={<LocaleFragment message={localeV2.common.seeAllFlavours} />}
              className="margin-bottom-24px"
              direction="right"
              isDark={!isVeev}
              isLayoutRightToLeft={isLayoutRightToLeft}
              onBtnClick={isRetailerLocationStoreTypeEcommerce ? handleEcommerceSeeAllFlavours : open}
            />
          )}

          <div className="ta-v2-result-page-pagination-container" />

          {isOpenIntegration ? null : (
            <CTANavigator
              btnText={<LocaleFragment message={localeV2.common.startOver} />}
              className="margin-bottom-40px text-underlined text-center"
              isDark={!isVeev}
              onBtnClick={startOver}
            />
          )}
        </div>

        {isMentholDisclaimerAddon && isProductWithCoolingEssencePresent && <MentholDisclaimer />}
        {isResultLongDisclaimerAddon && <ResultLongDisclaimer />}
        {isEnableLeviaBannerAddon && isLeviaBannerAvailable && <LeviaBanner />}
        {isEnableTereaBannerAddon && <TereaBanner />}
        {isEnableVeevBannerAddon && <VeevBanner />}
        {isShowHealthWarningFooter && (
          <div ref={footerRef} className="ta-v2-result-page-footer-container">
            <HealthWarning />
          </div>
        )}
        {isHealthWarningFooterToIsraelAddon && <HealthWarningFooterToIsrael />}
      </div>
      <PortfolioSection
        gprls={isSentiaEnabled ? filteredGprls : gprls}
        isAromaNoteSecondaryAddon={isAromaNoteSecondaryAddon}
        isAromaNotesDisabledAddon={isAromaNotesDisabledAddon}
        isEssenceAromaDisabledAddon={isEssenceAromaDisabledAddon}
        isEssenceBodyDisabledAddon={isEssenceBodyDisabledAddon}
        isEssenceCoolingDisabledAddon={isEssenceCoolingDisabledAddon}
        isMentholDisclaimerAddon={isMentholDisclaimerAddon}
        isOpen={isOpen}
        isProductLongDescriptionHiddenAddon={isProductLongDescriptionHiddenAddon}
        isProductShortDescriptionHiddenAddon={isProductShortDescriptionHiddenAddon}
        isRestrictedProductCardsAddon={isRestrictedProductCardsAddon}
        isRestrictedRecommendationAddon={isRestrictedRecommendationAddon}
        isResultLongDisclaimerAddon={isResultLongDisclaimerAddon}
        isSentiaEnabled={isSentiaEnabled}
        isTereaHideEssencesAddon={isTereaHideEssencesAddon}
        isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
        isVeev={isVeev}
        openTereaPortfolio={handleSeeAllTerea}
        selectedProductCategory={selectedProductCategory}
        onClose={close}
      />
    </>
  );
};

export default ResultPage;

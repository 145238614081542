import { createSelector } from 'reselect';

import { TProductCategory } from '@lib/core/products/types';
import { selectRetailerCustomStyle, selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { IRetailerLocationState } from '@lib/core/retailers/slices/retailerLocation';
import { TAssociatedKioskRetailerLocation, TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { TAddons, TAddonsPmi, TAddonsVinhood } from '@lib/core/retailers/types/addons';
import { TCommonDesignSets, TDesignSetName, TDesignSets, TPmiDesignSets } from '@lib/core/retailers/types/designSets';
import { IRetailerLocation } from '@lib/core/retailers/types/retailerLocation';
import { RetailerLocationStoreType } from '@lib/core/retailers/utils/consts';
import {
  DESIGN_SET_PMI_CRAFTED,
  DESIGN_SET_PMI_V2,
  DESIGN_SET_VINHOOD_APP,
  DESIGN_SET_VINHOOD_EXPERIENCE,
  isApplicationPmi,
} from '@lib/core/service/consts';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { VH_RETAILER_URL_SLUG_APP } from '@lib/tools/shared/helpers/consts';
import { ITheme } from '@lib/tools/shared/helpers/interfaces';

/**
 * @returns the whole retailerLocation state
 */
const selectRetailerLocationState = (state): IRetailerLocationState => state.retailerLocation || {};

/**
 * @returns status of loading retailerLocation state
 */
export const selectIsRetailerLocationLoading: (state) => boolean = createSelector(
  [selectRetailerLocationState],
  retailerLocation => retailerLocation?.isLoading || false,
);

/**
 * @returns {IRetailerLocation} the current retailer location object
 */
export const selectRetailerLocation: (state) => IRetailerLocation | Record<string, never> = createSelector(
  [selectRetailerLocationState],
  retailerLocation => retailerLocation?.data || {},
);

/**
 * @returns {string} the retailer location name
 */
export const selectRetailerLocationName: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.name || retailerLocationData?.slug || '',
);

/**
 * @returns {string} the retailer location website
 */
export const selectRetailerLocationWebsite: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.producer?.website || '',
);

/**
 * @returns {string} the retailer location logo
 */
export const selectRetailerLocationLogo: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.producer?.image || '',
);

/**
 * @returns {string} the retailer location phone number
 */
export const selectRetailerLocationPhone: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.phone_number || '',
);

/**
 * @returns {string} the retailer location address
 */
export const selectRetailerLocationAddress: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.location?.address || '',
);

/**
 * @returns {number[] | null} the retailer location longitude and latitude array
 */
export const selectRetailerLocationCoordinates = createSelector([selectRetailerLocation], ({ location }) => {
  const { latitude = '', longitude = '' } = location?.location || {};
  return latitude && longitude ? [Number(latitude), Number(longitude)] : null;
});

/**
 * @returns {string} the retailer location email
 */
export const selectRetailerLocationEmail: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.email || '',
);

/**
 * @returns {string} the retailer location description
 */
export const selectRetailerLocationDescription: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.description || '',
);

/**
 * @returns {string} the retailer location images
 */
export const selectRetailerLocationImages: (state) => string[] = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.images || [],
);

/**
 * @returns {TProductCategory[]} the retailer location's supported product categories
 */
export const selectRetailerLocationProductCategories: (state) => TProductCategory[] = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.product_categories || [],
);

/**
 * @returns {TDesignSetName} the design set for the required product category.
 */
export const selectRetailerDesignSet = (state): TDesignSetName => {
  const productCategory = selectServiceProductCategory(state);
  const retailerSlug = selectRetailerSlug(state);
  const retailerLocation = selectRetailerLocation(state);
  if (retailerSlug === VH_RETAILER_URL_SLUG_APP) {
    return DESIGN_SET_VINHOOD_APP;
  }
  return (retailerLocation?.design_sets?.[productCategory] || '') as TDesignSetName;
};

/**
 * @returns {TDesignSetName} all DesignSets for the retailer.
 */

export const selectRetailerDesignSets: (state) => Record<TProductCategory, TDesignSetName> = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.design_sets || {},
);

/**
 * @returns {string} the retailer location's slug
 */

export const selectRetailerLocationSlug: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.slug || '',
);

/**
 * @returns {string[]} the addons applied on the retailer location.
 */

export const selectRetailerLocationAddOns: (state) => string[] = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.addons || [],
);

/**
 * @returns the physical kiosk attached to mobile kiosk
 */
export const selectAssociatedRetailerLocation: (state) => TAssociatedKioskRetailerLocation = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.associated_kiosk_retailer_location || null,
);

/**
 * @returns the physical kiosk location slug attached to mobile kiosk
 */
export const selectAssociatedRetailerLocationSlug: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.associated_kiosk_retailer_location?.slug || '',
);

/**
 * @returns {string} retailer comms text.
 */
export const selectRetailerCommunicationSetText: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.retailer_communication?.text || '',
);

/**
 * @returns {string} the retailer location's identifier.
 */

export const selectRetailerLocationId: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.identifier || '',
);

/**
 * @returns {string} the retailer location's catalog type.
 */
export const selectRetailerCatalogType: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.catalog_type || '',
);

/**
 * @returns {string} the retailer location's physical address.
 */
export const selectRetailerPhysicalAddress: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.physical_address || '',
);

/**
 * @returns {string} the retailer location's producer Id.
 */
export const selectRetailerLocationProducerId: (state) => string = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.producer?.identifier || '',
);

/**
 * @returns {string} the retailer location's taste match data.
 */
export const selectRetailerLocationTasteMatch: (state) => Record<string, number> = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.taste_match || {},
);

/**
 * @returns {TRetailerLocationStoreType} the retailer location's store type
 */
export const selectRetailerLocationStoreType: (state) => TRetailerLocationStoreType = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.store_type || RetailerLocationStoreType.main,
);

export const selectIsRetailerLocationStoreTypeEcommerce: (state) => boolean = createSelector(
  [selectRetailerLocation],
  retailerLocationData => retailerLocationData?.store_type === RetailerLocationStoreType.ecommerce || false,
);

/**
 * @returns {IRetailerLocation[]} list of available locations for current retailer
 */
export const selectRetailerLocationsList: (state) => IRetailerLocation[] = createSelector(
  [selectRetailerLocationState],
  retailerLocation => retailerLocation?.list || [],
);

export const selectRetailerLocationCustomStyle: (state) => ITheme = createSelector(
  [selectRetailerLocationId, selectRetailerCustomStyle],
  (retailerLocationId, retailerCustomStyle) => {
    if (!!retailerLocationId && !!retailerCustomStyle) {
      return { ...retailerCustomStyle?.[retailerLocationId] };
    }
    return {};
  },
);

/**
 * @todo Remove consts
 * @returns the retailer location's design set
 */
export const selectAppliedDesignSet: (state) => TDesignSets = createSelector(
  [selectRetailerDesignSet],
  retailerDesignSetSelect => {
    const retailerDesignSet: TDesignSetName = retailerDesignSetSelect;
    const pmiDesignSets: Record<TPmiDesignSets, boolean> = {
      isDesignSetPmiCrafted: retailerDesignSet === DESIGN_SET_PMI_CRAFTED,
      isDesignSetPmiV2: retailerDesignSet === DESIGN_SET_PMI_V2,
    };

    const commonDesignSets: Record<TCommonDesignSets, boolean> = {
      isDesignSetPmi: Object.values(pmiDesignSets).some(Boolean),
      isDesignSetVinhoodApp: retailerDesignSet === DESIGN_SET_VINHOOD_APP,
      isDesignSetVinhoodExperience: retailerDesignSet === DESIGN_SET_VINHOOD_EXPERIENCE,
    };

    return {
      retailerDesignSet,
      ...pmiDesignSets,
      ...commonDesignSets,
    } as const;
  },
);

/**
 * @returns the retailer location's addons
 */
export const selectAppliedAddOns: (state) => TAddons = createSelector([selectRetailerLocationAddOns], addons => {
  if (isApplicationPmi) {
    return {
      isAddToCartAddon: addons.includes('add-to-cart'),
      isAgeGateAddon: addons.includes('age-gate'),
      isAromaNoteSecondaryAddon: addons.includes('aroma-note-secondary'),
      isAromaNotesDisabledAddon: addons.includes('hide-aroma-notes'),
      isCustomInfoPageAddon: addons.includes('custom-info-page'),
      isDisableRetailerAddon: addons.includes('disable-retailer'),
      isEnableBuyEcommerceAddon: addons.includes('enable-buy-ecommerce'),
      isEnableLeviaBannerAddon: addons.includes('enable-levia-banner'),
      isEnableProductBaseLoopAddon: addons.includes('enable-product-base-loop'),
      isEnableTereaBannerAddon: addons.includes('enable-terea-banner'),
      isEnableVeevBannerAddon: addons.includes('enable-veev-banner'),
      isEssenceAromaDisabledAddon: addons.includes('hide-essence-aroma'),
      isEssenceBodyDisabledAddon: addons.includes('hide-essence-body'),
      isEssenceCoolingDisabledAddon: addons.includes('hide-essence-cooling'),
      isGoogleAnalyticsDisabledAddon: addons.includes('disable-google-analytics'),
      isHealthWarningFooterAddon: addons.includes('health-warning-footer'),
      isHealthWarningFooterToIsraelAddon: addons.includes('health-warning-footer-to-israel'),
      isHealthWarningPrepageAddon: addons.includes('health-warning-prepage'),
      isHealthWarningQuizAddon: addons.includes('health-warning-quiz'),
      isHideIqosAddon: addons.includes('hide-iqos'),
      isIgnoreProductSeparationAddon: addons.includes('ignore-product-separation'),
      isMentholDisclaimerAddon: addons.includes('menthol-disclaimer'),
      isProductLongDescriptionHiddenAddon: addons.includes('hide-product-long-description'),
      isProductShortDescriptionHiddenAddon: addons.includes('hide-product-short-description'),
      isRestrictedBundlesPageAddon: addons.includes('restricted-bundles-page'),
      isRestrictedProductCardsAddon: addons.includes('restricted-product-cards'),
      isRestrictedQuizPageAddon: addons.includes('restricted-quiz-page'),
      isRestrictedRecommendationAddon: addons.includes('restricted-recommendation'),
      isRestrictedResultPageAddon: addons.includes('restricted-result-page'),
      isResultLongDisclaimerAddon: addons.includes('result-long-disclaimer'),
      isTereaHideEssencesAddon: addons.includes('terea-hide-essences'),
      isTobaccoExperienceHiddenAddon: addons.includes('hide-tobacco-experience'),
      isWyngEcommerceAddon: addons.includes('wyng-ecommerce'),
    } as TAddonsPmi;
  }

  return {
    isApplyCustomStyle: addons.includes('apply-custom-styles'),
    isDisableHomeAddon: addons.includes('disable-home'),
    isDisableLocationTrackAddon: addons.includes('disable-location-track'),
    isDisableQuizNoGluten: addons.includes('disable-quiz-no-gluten'),
    isDisableRecipeCTAHomeAddon: addons.includes('disable-recipe-cta-home'),
    isEnableAppBanner: addons.includes('enable-app-banner'),
    isEnableKioskFidelityPromotionAddon: addons.includes('enable-kiosk-fidelity-promotion'),
    isEnableKioskFidelityScanAddon: addons.includes('enable-kiosk-fidelity-scan'),
    isEnableLocationMapAddon: addons.includes('enable-location-map'),
    isEnableScanBarcodeAddon: addons.includes('enable-scan-barcode'),
    isEnableTastePathAddon: addons.includes('enable-taste-path'),
    isEnableVusionAddon: addons.includes('enable-vusion'),
    isExternalCharacterPage: addons.includes('external-character-page'),
    isFeatureProductsAddon: addons.includes('feature-products'),
    isHideFeedbackAddon: addons.includes('hide-feedback'),
    isHideProductPageRecipes: addons.includes('hide-product-page-recipes'),
    isInteractiveTastingAddon: addons.includes('interactive-tasting'),
    isNoEmailAddon: addons.includes('no-email'),
    isPriceRangeAddon: addons.includes('price-range'),
    isProductPDFAddon: addons.includes('enable-product-pdf'),
    isShowProductLocationAddon: addons.includes('show-product-location'),
    isShowSkipAndGoToProductsAddon: addons.includes('show-skip-and-go-to-products'),
    isTasteIdResultPageAddon: addons.includes('taste-id-result-page'),
  } as TAddonsVinhood;
});

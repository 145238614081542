import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { ReactComponent as ChevronDown } from '@app/pmi/src/assets/media/V2/ChevronDown.svg';
import { ReactComponent as ChevronUp } from '@app/pmi/src/assets/media/V2/ChevronUp.svg';
import { GA_EVENT_CALL_TO_ACTION, trackCallToAction } from '@app/pmi/src/comms/events';

import { useApp } from '@lib/core/service/hooks';
import { localeV2 } from '@lib/tools/locale/source/pmi/v2';
import LocaleFragment from '@lib/tools/locale/views/LocaleFragment';
import { useHeight } from '@lib/tools/views/hooks';

interface Props {
  name: string;
  image: string;
  caption: string;
  description: string;
  noCharacter?: boolean;
  isThreeProductsShown?: boolean;
  isUserQuizTypeShortRecommender?: boolean;
}

const CharacterHeader: FC<Props> = ({
  name,
  image,
  caption,
  description,
  noCharacter,
  isThreeProductsShown,
  isUserQuizTypeShortRecommender,
}) => {
  const [showDescription, setShowDescription] = useState(false);

  const [heightRef, height] = useHeight<HTMLDivElement>();
  const { isLayoutRightToLeft } = useApp();

  const slideInStyles = useSpring({
    config: { duration: 200 },
    from: { height: 0, opacity: 0 },
    to: {
      height: showDescription ? height : 0,
      opacity: showDescription ? 1 : 0,
    },
  });

  const staticText = <LocaleFragment message={localeV2.result.characterHeader} />;

  useEffect(() => {
    if (showDescription) trackCallToAction(GA_EVENT_CALL_TO_ACTION.RESULT_DESCRIPTION);
  }, [showDescription]);

  if (noCharacter) {
    return (
      <div
        className={cn('character-header no-character', {
          isThreeProductsShown,
        })}
      >
        <span className="character-header-no-character-text font-ta-v2-global-caption-medium">
          <LocaleFragment message={localeV2.result.characterHeaderNoCharacterText} />
        </span>
      </div>
    );
  }

  if (isUserQuizTypeShortRecommender) {
    return (
      <div
        className={cn('character-header short-recommender', {
          isThreeProductsShown,
        })}
      >
        <span className="font-ta-v2-title-6">
          <LocaleFragment message={localeV2.result.headerShortRecommenderTitle} />
        </span>
        <span className="font-ta-v2-global-body-large">
          <LocaleFragment message={localeV2.result.headerShortRecommenderCaption} />
        </span>
      </div>
    );
  }

  return (
    <div
      role="button"
      style={{ backgroundImage: `url(${image})` }}
      tabIndex={0}
      className={cn('character-header', {
        isThreeProductsShown,
      })}
      onClick={() => setShowDescription(state => !state)}
      onKeyDown={() => setShowDescription(state => !state)}
    >
      <div className={`character-header-content ${showDescription ? 'full-content' : ''}`}>
        <div className="character-header-block-title">
          <div
            className={cn('character-header-block-title-text', {
              'rtl-orientation': isLayoutRightToLeft,
            })}
          >
            <div className="font-ta-v2-global-caption-medium character-header-block-title-static">{staticText}</div>
            <div className="font-ta-v2-title-5 character-header-block-title-name">{name}</div>
          </div>
          <button
            aria-label={showDescription ? 'fold description' : 'unfold description'}
            className="character-header-block-title-button"
            type="button"
          >
            {showDescription ? <ChevronUp /> : <ChevronDown />}
          </button>
        </div>

        <animated.div style={{ ...slideInStyles, overflow: 'hidden' }}>
          <div
            ref={heightRef}
            className={cn('font-ta-v2-global-body-small character-header-block-info', {
              'rtl-orientation': isLayoutRightToLeft,
            })}
          >
            <span>
              {caption} {description}
            </span>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default CharacterHeader;

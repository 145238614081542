import { createSelector } from 'reselect';

import { TProductCategory } from '@lib/core/products/types';
import { RootState } from '@lib/core/service/types/appStateType';
import { ThirdPartyConnectModalShown } from '@lib/core/service/types/interface';
import { languages } from '@lib/tools/locale/utils/consts';
import RouteUtils from '@lib/tools/routes';

export const selectServiceState = (state: RootState) => state.service;

/**
 * @returns The value of the isDevToolsEnabled property or the defaultValue.
 */
export const selectIsDevToolsEnabled = (state: RootState, defaultValue = false): boolean =>
  state.service.isDevToolsEnabled || defaultValue;

/**
 * @returns a boolean value to mock the hosting site and iframe behavior with a mock 1080x1920 wrapper
 */
export const selectIsHostMocked = (state: RootState, defaultValue = false): boolean =>
  state.service.isHostMocked || defaultValue;

/**
 * @returns the edit mode for translations. Enables additional behavior on `LocaleFragment`
 */
export const selectIsLocaleEditMode = (state: RootState): boolean => state.service.isLocaleEditMode;

/**
 * @returns the first product category of the retailer. Used as default.
 */
export const selectServiceProductCategory = (
  state: RootState,
  defaultValue = RouteUtils.getProductCategory(),
): TProductCategory => state?.service?.productCategory || (defaultValue as TProductCategory);
/**
 * @returns A boolean indicating whether the taste path start page should be displayed.
 */
export const selectShouldShowTastePathStartPage = createSelector(
  [selectServiceState],
  (state): boolean => state.shouldShowTastePathStartPage,
);

export const selectIsTastePathOnboardingShown = createSelector(
  [selectServiceState],
  state => state.isTastePathOnboardingShown,
);

export const selectAppRoute = (state: RootState, defaultValue = ''): string => state?.service?.route || defaultValue;

export const selectAppPreviousRoute = (state: RootState, defaultValue = ''): string =>
  state?.service?.previousRoute || defaultValue;

/**
 * @returns the current language of the application
 */
export const selectLocale = (state: RootState, defaultValue = languages.ENGLISH): string =>
  state?.service?.lang || defaultValue;

/**
 * @returns A boolean indicating whether b2c app SplashLoading screen is shown.
 */

export const selectIsSplashScreenPageShown = (state: RootState, defaultValue = false): boolean =>
  state?.service?.isSplashScreenPageShown || defaultValue;

/**
 * @returns A boolean indicating whether Guide to Taste Id Page on Widget is shown.
 */

export const selectIsTasteIdGuideShown = (state: RootState, defaultValue = false): boolean =>
  state?.service?.isTasteIdGuideShown || defaultValue;

/**
 * @returns A boolean indicating whether Download App Card is to be hidden.
 */

export const selectShouldHideDownloadAppCard = (state: RootState, defaultValue = false): boolean =>
  state?.service?.shouldHideDownloadAppCard || defaultValue;

/**
 * @returns A boolean indicating whether third party connect modal is shown
 */
export const selectIsThirdPartyConnectModalShown = (
  state: RootState,
  defaultValue = {
    location: false,
    rate4: false,
    rate5: false,
    wishlist: false,
  },
): ThirdPartyConnectModalShown => state?.service?.isThirdPartyConnectModalShown || defaultValue;

/**
 * @returns Case which triggered the Third Party Connect Modal
 */
export const selectThirdPartyConnectModalScenario = (
  state: RootState,
  defaultValue = {
    productName: '',
    scenario: '',
  },
): {
  productName: string;
  scenario: string;
} => state?.service?.thirdPartyConnectModalOpenScenario || defaultValue;

/**
 * @returns A string for fidelityId received when being redirected to mobile kiosk.
 */
export const selectFidelityCardIdFromKiosk = (state: RootState, defaultValue = ''): string =>
  state?.service?.fidelityCardIdFromKiosk || defaultValue;

export const selectShouldShowScanBarcode = createSelector([selectServiceState], state => state.shouldShowScanBarcode);

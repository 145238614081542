// eslint-disable-next-line import/no-extraneous-dependencies
import { TokenResponse } from '@react-oauth/google';
import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { selectRetailerId, selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { DESIGN_SET_WINE_EXPERIENCE, REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import {
  facebookLoginApiUrlCreator,
  googleLoginApiUrlCreator,
  loginApiUrlCreator,
  registrationApiUrlCreator,
  resetPasswordApiUrlCreator,
  setNewPasswordApiUrlCreator,
} from '@lib/core/users/slices/urls';
import { TUser } from '@lib/core/users/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { removeSocialValuesLocalStorage } from '@lib/tools/shared/helpers/removeSocialValuesLocalStorage';
import { ISocialLoginFacebook } from '@lib/tools/views/web/interfaces/loginSocial';

export interface IRegisterParams {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
}

interface ILoginRequestData {
  email: string;
  password: string;
  captchaValue: string;
  isCaptchaDisabled: boolean;
}

interface IAuthData {
  access_token: string;
  refresh_token: string;
}

export interface ILoginResponse extends IAuthData {
  user: TUser;
}

interface IUserSlice {
  data: IAuthData;
  isLoading: boolean;
  error: string;
  rejectEndpointError: string;
  resetPasswordEmailSuccess: boolean;
}

const initialState: IUserSlice = {
  data: undefined,
  error: '',
  isLoading: false,
  rejectEndpointError: '',
  resetPasswordEmailSuccess: false,
};

export const actionSetNewPassword = createTypedAsyncThunk(
  'users/auth/actionSetNewPassword',
  async ({ password, token, uid }: { password: string; uid: string; token: string }) => {
    return await request(
      setNewPasswordApiUrlCreator(),
      { method: REQUEST_METHODS.POST },
      {
        new_password1: password,
        new_password2: password,
        token,
        uid,
      },
    );
  },
);

export const actionResetPassword = createTypedAsyncThunk(
  'users/auth/actionResetPassword',
  async ({ email, sitekey }: { email: string; sitekey?: string }, { getState, rejectWithValue }) => {
    const body = {
      design_set: DESIGN_SET_WINE_EXPERIENCE,
      email,
      retailer_slug: selectRetailerSlug(getState()),
      sitekey,
    };
    try {
      return await request(resetPasswordApiUrlCreator(), { method: REQUEST_METHODS.POST }, body);
    } catch (error: any) {
      /**
       * @todo add backend error
       */
      return rejectWithValue(error?.data?.email?.length ? error?.data?.email[0] : 'Error');
    }
  },
);

export const actionLogin = createTypedAsyncThunk(
  'users/auth/actionLogin',
  async ({ email, password, captchaValue, isCaptchaDisabled }: ILoginRequestData, { rejectWithValue }) => {
    try {
      return await request(
        loginApiUrlCreator(),
        {
          method: REQUEST_METHODS.POST,
        },
        { email, password, sitekey: isCaptchaDisabled ? undefined : captchaValue },
      );
    } catch (error: any) {
      return rejectWithValue(error?.data?.detail ? error?.data?.detail : 'Error');
    }
  },
);

export const actionGoogleLogin = createTypedAsyncThunk(
  'users/auth/actionGoogleLogin',
  async ({ access_token }: TokenResponse, { getState, rejectWithValue }) => {
    if (!access_token) {
      rejectWithValue({ message: 'Google auth API error: no token returned' });
    }
    removeSocialValuesLocalStorage();
    return await request(
      googleLoginApiUrlCreator(),
      {
        method: REQUEST_METHODS.POST,
      },
      { access_token, retailer: selectRetailerId(getState()) },
    );
  },
);

export const actionFacebookLogin = createTypedAsyncThunk(
  'users/auth/actionFacebookLogin',
  async ({ accessToken }: ISocialLoginFacebook, { getState, rejectWithValue }) => {
    if (!accessToken) {
      rejectWithValue({ message: 'Facebook auth API error: no token returned' });
    }
    removeSocialValuesLocalStorage();
    return await request(
      facebookLoginApiUrlCreator(),
      {
        method: REQUEST_METHODS.POST,
      },
      { access_token: accessToken, retailer: selectRetailerId(getState()) },
    );
  },
);

export const actionRegister = createTypedAsyncThunk(
  'users/auth/actionRegister',
  async (requestData: IRegisterParams, { getState, rejectWithValue }) => {
    try {
      const newUserData = await request(
        registrationApiUrlCreator(),
        { method: REQUEST_METHODS.POST },
        { ...requestData, password2: requestData.password, retailer: selectRetailerId(getState()) },
      );
      MixpanelTracker.events.userRegistrationCompleted();

      return newUserData;
    } catch (error: any) {
      return rejectWithValue(error?.data?.email ? error?.data?.email[0] : 'Error');
    }
  },
);

export const googleLoginCallback = dispatch => response => dispatch(actionGoogleLogin(response));

export const facebookLoginCallback = dispatch => response => dispatch(actionFacebookLogin(response));

export const authSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionResetPassword.fulfilled, state => {
      state.isLoading = false;
      state.rejectEndpointError = '';
      state.resetPasswordEmailSuccess = true;
    });
    builder.addCase(actionResetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.rejectEndpointError = action.payload?.message || action.error?.message;
      state.resetPasswordEmailSuccess = false;
    });
    builder.addMatcher(
      isAnyOf(actionLogin.pending, actionGoogleLogin.pending, actionFacebookLogin.pending, actionRegister.pending),
      state => {
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(
        actionLogin.fulfilled,
        actionGoogleLogin.fulfilled,
        actionFacebookLogin.fulfilled,
        actionRegister.fulfilled,
      ),
      (state, action: PayloadAction<IAuthData>) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { payload: { access_token, refresh_token } = {} } = action;
        state.data = {
          access_token,
          refresh_token,
        };
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(actionLogin.rejected, actionGoogleLogin.rejected, actionFacebookLogin.rejected, actionRegister.rejected),
      (state, action: any) => {
        state.isLoading = false;
        state.error = action.payload || 'Error';
      },
    );
  },
  initialState,
  name: 'auth',
  reducers: {
    actionResetAuthSlice: () => initialState,
    actionSaveAccessToken: (state, action: PayloadAction<string>) => {
      state.data = { ...state.data, access_token: action.payload };
    },
    resetAuthError: state => {
      state.error = '';
      state.rejectEndpointError = '';
    },
    resetResetPasswordState: state => {
      state.resetPasswordEmailSuccess = false;
    },
  },
});

export default authSlice.reducer;

export const { actionResetAuthSlice, actionSaveAccessToken, resetAuthError, resetResetPasswordState } =
  authSlice.actions;

import {
  ITEM_ID_REPLACEMENT,
  RETAILER_LOCATION_SLUG_FOR_REPLACEMENT,
  RETAILER_SLUG_FOR_REPLACEMENT,
  STORE_ID_REPLACEMENT,
} from '@lib/core/service/consts';

/* eslint-disable */
const backendApiUrls = {
  loginUrl: '/users/rest-auth/token/',
  refreshTokenUrl: '/users/rest-auth/token/refresh/',

  apiUrlRecipeAutoSuggest: `/products/${RETAILER_SLUG_FOR_REPLACEMENT}/recipes-autosuggest/`,

  // Get product attributes
  apiUrlGetListFormats: `/product-attributes/formats/${RETAILER_SLUG_FOR_REPLACEMENT}/${RETAILER_LOCATION_SLUG_FOR_REPLACEMENT}/coffee/`,

  apiUrlSendDynamoRedirect: '/dynamodb/redirect/',

  webSocketApis: {
    remoteAuthUrl: '/ws/remote-kiosk-auth/',
  },

  // Vusion API
  apiUrlPostFlashLabel: `/products/${RETAILER_SLUG_FOR_REPLACEMENT}/ex-call/vusion/store/${STORE_ID_REPLACEMENT}/item/${ITEM_ID_REPLACEMENT}/flash-labels/`,

  // B2C
  apiUrlBanners: `/banners/${RETAILER_SLUG_FOR_REPLACEMENT}/${RETAILER_LOCATION_SLUG_FOR_REPLACEMENT}/?limit=1000`,
};

export default backendApiUrls;

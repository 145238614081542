// Quiz types
export const QUIZ_TYPE_TASTE = 'taste';
export const QUIZ_TYPE_RECIPE = 'recipe';
export const QUIZ_TYPE_MULTI_PRODUCT = 'multi-product';
export const QUIZ_TYPE_SITUATIONAL = 'situational';
export const QUIZ_TYPE_EXPERT = 'expert';
export const QUIZ_TYPE_TASTE_ECOMMERCE = 'taste-ecommerce';
export const QUIZ_TYPE_TASTE_LOCAL = 'taste-local';
export const QUIZ_TYPE_TASTE_SHORT_RECOMMENDER = 'taste-short-recommender';

// URL Quiz types
export const URL_QUIZ_TYPE_TASTE_REMOTE = 'taste-remote';

// Quiz context
export const PRODUCT_CATEGORY_TAG_CONTEXT = 'product-category';
export const FILTER_CHARACTERS_TAG_CONTEXT = 'filter-characters';
export const FILTER_PRODUCT_PREFERENCES_TAG_CONTEXT = 'filter-product-preferences';
export const FILTER_REDIRECT_ROUTE_TAG_CONTEXT = 'redirect-route';

export const QUIZ_SLUG_REPLACEMENT = ':quiz_slug';

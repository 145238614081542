import { HARDCODED_PRODUCT_RATE_DATA } from '@lib/core/service/consts';
import { IFilterItem } from '@lib/tools/filterManager/slices/sublistFilterSlice';

// Url params
export const PREFERENCES_URL_PARAM = 'preferences';
export const PRODUCT_CATEGORY_URL_PARAM = 'productCategory';
export const CHARACTERS_URL_PARAM = 'characters';
export const SITUATIONAL_PRESELECTED_URL_PARAM = 'preselected';
export const SITUATIONAL_PRESELECTED_PRODUCT_CATEGORY_URL_PARAM = 'preselectedProductCategory';
export const PRODUCT_ID_URL_PARAM = 'productID';
export const RETAILER_LOCATION_ID_URL_PARAM = 'retailerLocationId';
export const RETAILER_SLUG_URL_PARAM = 'retailerSlug';
export const FIDELITY_ID_URL_PARAM = 'fidelityID';
export const IS_FROM_KIOSK_QR_URL_PARAM = 'isQR';
export const TAB_URL_PARAM = 'tab';

// Open-search API queries
export const PRODUCT_CATEGORY_QUERY = 'product_category';
export const GPRL_IDENTIFIER_QUERY = 'identifier';
export const PRODUCT_IDENTIFIER_QUERY = 'product_identifier';

export const OS_PRODUCT_NAME_QUERY = 'product_name';

// Dietary preferences
export const DIETARY_PREFERENCE_EXPOSURE = {
  MOOD: 'mood',
  PREFERENCES: 'preferences',
  TAGS: 'tags',
};

export const DIETARY_PREFERENCE_SLUG = {
  NO_DAIRY: 'no-dairy',
  NO_FOOD_PREFERENCES: 'no-food-preferences',
  NO_GLUTEN: 'no-gluten',
  NO_MEAT: 'no-meat',
  NO_PREFERENCES: 'no-preferences',
};

// Legal page subpages
export const LEGAL_PAGE_TERMS_AND_CONDITIONS_PAGE = 'terms-and-conditions';
export const LEGAL_PAGE_COOKIE_POLICY_PAGE = 'cookie-policy';
export const LEGAL_PAGE_PRIVACY_POLICY_PAGE = 'privacy-policy';

// Widget Catalog tabs
export const TASTE_PATH_TAB = 'taste-path';
export const ALL_PRODUCTS_TAB = 'all-products';

export const PRODUCT_ALL = 'all';
export const PRODUCT_CATEGORY_WINE = 'wine';
export const PRODUCT_CATEGORY_BEER = 'beer';
export const PRODUCT_CATEGORY_BREAD = 'bread';
export const PRODUCT_CATEGORY_COFFEE = 'coffee';
export const PRODUCT_CATEGORY_TEA = 'tea';
export const PRODUCT_CATEGORY_OIL = 'oil';
export const PRODUCT_CATEGORY_CANDY = 'candy';
export const PRODUCT_CATEGORY_CHOCOLATE = 'chocolate';
export const PRODUCT_CATEGORY_DIGESTIF = 'digestif';

export const PRODUCT_CATEGORIES_ORDERED = [PRODUCT_CATEGORY_WINE, PRODUCT_CATEGORY_BEER, PRODUCT_CATEGORY_COFFEE];

export const VH_RETAILER_URL_SLUG_APP = 'vh-app';

// Catalog filter data-keys
export const CHARACTERISTICS = 'characteristics';
export const FORMAT = 'formats';
export const PRICE = 'price';
export const ORIGINS = 'regions';
export const STYLE = 'styles';
export const FILTER_TYPE_CHARACTER_TOGGLE = 'character-toggle';
export const FILTER_TYPE_PROMOTION_TOGGLE = 'promotion-toggle';
export const FILTER_TYPE_ORIGIN = 'origin';
export const FILTER_TYPE_CHARACTERISTICS = 'characteristics';
export const FILTER_TYPE_FORMAT = 'format';
export const FILTER_TYPE_STYLE = 'style';
export const FILTER_TYPE_WISHLIST = 'isWishlistToggleActive';
export const FILTER_TYPE_RATING = 'rating';

export const FILTER_TYPE_MOOD = 'mood';
export const FILTER_TYPE_PRICE_RANGE = 'range';
export const FILTER_TYPE_PRICE_RANGE_MAX = 'max-price-range';
export const FILTER_TYPE_PRICE_RANGE_MIN = 'min-price-range';
export const FILTER_TYPE_ITEM_NAME = 'product-name';
export const FILTER_TYPE_PRODUCT_CATEGORY = 'productCategory';
export const FILTER_TYPE_LOCATION = 'location';
export const FILTER_TYPE_SHOW_FAVORITES = 'showOnly';
export const FILTER_TYPE_ORDERING = 'priority';
export const ORDERING_TYPE_PRICE = 'price';
export const ORDERING_TYPE_RANK = 'rank';

// Recipe type slugs
export const CLASSIC_RECIPE_TYPE_SLUG = 'classic';
export const HEALTHY_RECIPE_TYPE_SLUG = 'healthy';
export const INGREDIENT_RECIPE_TYPE_SLUG = 'ingredient';

export const PROMOTION_LABEL_SLUG = 'label';
export const PROMOTION_BADGE_FIDELITY = 'bottom-badge-fidelity';
export const PROMOTION_BADGE_PROMOTION = 'bottom-badge-promotion';
export const PROMOTION_BADGE_OTHER = 'bottom-badge-other';

export const PROMOTION_FEATURED_SLUG = 'featured';

export enum FidelityModalsTypes {
  NotFoundCard = 1,
  SuccessWithCharacter,
  SuccessWithoutCharacter,
  CardDisconnected,
}

// Characters Keys
export const MAP_SUB_CATEGORY = 'map_subcategory_';
export const STYLE_NAME = 'name_';

// Authorization buttons
export const GOOGLE = 'google';
export const FACEBOOK = 'facebook';
export const APPLE = 'apple';
export const EMAIL = 'email';

// Widget language detection methods
export const LANGUAGE_DETECTION_METHOD = {
  na: 'na',
};

// Product type colors
export const PRODUCT_CATEGORY_COLORS = {
  primary: {
    beer: '#EB7F00',
    coffee: '#150400',
    wine: '#9E005D',
  },
  secondary: {
    beer: '#F5BC7B',
    coffee: '#8A8180',
    wine: '#CE80AE',
  },
};

// Price range for VH
export const WINE_PRICE_RANGE = [5.99, 10.99, 20.99, 21];
export const BEER_PRICE_RANGE = [2.99, 4.99, 6.99, 7.0];
export const DEFAULT_PRICE_RANGE = [2.99, 4.99, 6.99, 7.0];
export const EXPERIENCES_PRICE_RANGE = [30, 50, 100];

// APP Variants
export const VH_VARIANTS = {
  BEER: 'beer',
  COFFEE: 'coffee',
  DANGER: 'danger',
  DARK: 'dark',
  INFO: 'info',
  LIGHT: 'light',
  LINK: 'link',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
  WINE: 'wine',
} as const;

export const MODALS = {
  DOWNLOAD_APP_MODAL: 'downloadAppModal',
  TERMS_OF_SERVICE_BASIC_MODAL: 'termsOfServiceBasicModal',
  THIRD_PARTY_CONNECT_MODAL: 'thirdPartyConnectModal',
} as const;

// Taste Path
export const DEFAULT_MAX_LENGTH_TASTE_PATH_PER_CATEGORY = 3;
export const TASTE_PATH_PRODUCT_STYLES = {
  [PRODUCT_CATEGORY_BEER]: {
    CT0002: { name_en: 'Hoppy', name_it: 'Luppolate', order: 2 },
    CT0007: { name_en: 'Intense', name_it: 'Intense', order: 4 },
    CT0010: { name_en: 'Light', name_it: 'Leggere', order: 1 },
    CT0011: { name_en: 'Toasted', name_it: 'Tostate', order: 3 },
  },
  [PRODUCT_CATEGORY_COFFEE]: {
    CT0006: { name_en: 'Intense', name_it: 'Intense', order: 3 },
    CT0008: { name_en: 'Atypical', name_it: 'Atipici', order: 2 },
    CT0009: { name_en: 'Delicate', name_it: 'Delicati', order: 1 },
  },
  [PRODUCT_CATEGORY_WINE]: {
    CT0001: { name_en: 'Sparkling', name_it: 'Bollicine', order: 1 },
    CT0002: { name_en: 'Red', name_it: 'Rossi', order: 4 },
    CT0003: { name_en: 'White', name_it: 'Bianchi', order: 2 },
    CT0004: { name_en: 'Sweet', name_it: 'Dolci', order: 5 },
    CT0005: { name_en: 'Rosè & Orange', name_it: 'Rosati & Orange', order: 3 },
  },
};

export const TASTE_PATH_PRODUCT_STYLES_IDS = {
  [PRODUCT_CATEGORY_BEER]: Object.keys(TASTE_PATH_PRODUCT_STYLES[PRODUCT_CATEGORY_BEER]),
  [PRODUCT_CATEGORY_COFFEE]: Object.keys(TASTE_PATH_PRODUCT_STYLES[PRODUCT_CATEGORY_COFFEE]),
  [PRODUCT_CATEGORY_WINE]: Object.keys(TASTE_PATH_PRODUCT_STYLES[PRODUCT_CATEGORY_WINE]),
};

export const VARIANT_SET_FULLPAGE = 'fullpage';
export const VARIANT_SET_EMBEDDED = 'embedded';
export const VARIANT_SET_JOURNAL = 'journal';

// Discovery Quiz
export const DISCOVER_QUIZ_STEP_START_QUIZ = 0;
export const DISCOVER_QUIZ_STEP_ANSWER_AROMA = 1;
export const DISCOVER_QUIZ_STEP_ANSWER_TASTE = 2;
export const DISCOVER_QUIZ_TASTE_ATTRIBUTE = 'taste';
export const DISCOVER_QUIZ_AROMAS_ATTRIBUTE = 'aromas';
export const DISCOVERY_QUIZ_RANDOM_PRODUCTS_COUNT = 2;

export const IS_REDIRECTED_FROM_WIDGET = 'isRedirectedFromWidget';

// Filters
export const HARDCODED_RATING_FILTER: Record<string, IFilterItem> = {
  10: {
    filterType: FILTER_TYPE_RATING,
    icon: HARDCODED_PRODUCT_RATE_DATA[10].icon.activeIcon,
    isActive: false,
    name: '10',
    value: '10',
  },
  20: {
    filterType: FILTER_TYPE_RATING,
    icon: HARDCODED_PRODUCT_RATE_DATA[20].icon.activeIcon,
    isActive: false,
    name: '20',
    value: '20',
  },
  30: {
    filterType: FILTER_TYPE_RATING,
    icon: HARDCODED_PRODUCT_RATE_DATA[30].icon.activeIcon,
    isActive: false,
    name: '30',
    value: '30',
  },
  40: {
    filterType: FILTER_TYPE_RATING,
    icon: HARDCODED_PRODUCT_RATE_DATA[40].icon.activeIcon,
    isActive: false,
    name: '40',
    value: '40',
  },
  50: {
    filterType: FILTER_TYPE_RATING,
    icon: HARDCODED_PRODUCT_RATE_DATA[50].icon.activeIcon,
    isActive: false,
    name: '50',
    value: '50',
  },
};

// Product Lists
export const PRODUCT_LIST_WISHLIST = 'wishlist';

export const IS_FROM_CATALOG_PAGE_URL_PARAM = 'isFromCatalogPage';

export const PRODUCT_CARD_VARIANTS = {
  BADGES: 'badges',
  COMPACT: 'compact',
  DEFAULT: 'default',
  TOP_BANNER: 'topBanner',
} as const;

// catalog types
export const LIMITED_CATALOG = 'limited';
export const EXTENSIVE_CATALOG = 'extensive';

// Taste Id
export const JOURNAL_PRODUCT_VARIANT = 'product';
export const JOURNAL_PLACES_VARIANT = 'places';
export const JOURNAL_PANEL_PLACES_FILTER = 'places';
export const JOURNAL_PANEL_LOCATION_FILTER = 'location';

export type TJournalVariant = typeof JOURNAL_PRODUCT_VARIANT | typeof JOURNAL_PLACES_VARIANT;
export type TJournalPlacesPanelFilter = typeof JOURNAL_PANEL_PLACES_FILTER | typeof JOURNAL_PANEL_LOCATION_FILTER;

export const FLAG_MEDIA_DOMAIN = `https://media-staging.vinhood.com/media/country_flags/`;

// Banners
export const EDUTAINMENT_BANNER = 'edutainment';
export const TIP_AND_TRICKS_BANNER = 'tip-and-tricks';
export const PRODUCT_FEEDBACK_BANNER = 'product-feedback';

export const BANNER_ICONS = {
  bookmark: 'bookmark',
  box: 'box',
  cart: 'cart',
  compass: 'compass',
  doc: 'doc',
  filter: 'filter',
  'finger-print': 'finger-print',
  'fork-knife': 'fork-knife',
  list: 'list',
  promo: 'promo',
  rocket: 'rocket',
  save: 'save',
  star: 'star',
} as const;

export const AUTH_BANNERS = {
  adjective: 'adjective',
  app: 'app',
  authFeature: 'authFeature',
  character: 'character',
  click: 'click',
  experience: 'experience',
  plain: 'plain',
  quizFalse: 'quizFalse',
  quizTrue: 'quizTrue',
} as const;

export type TAuthBanner = (typeof AUTH_BANNERS)[keyof typeof AUTH_BANNERS];

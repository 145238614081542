import {
  selectQuizData,
  selectQuizViewAnswerTags,
  selectQuizViewDepth,
  selectQuizViewQuestionData,
  selectQuizViewQuestionDepths,
  selectQuizzes,
  selectUserQuizSlug,
} from '@lib/core/quizzes/selectors';
import { TQuiz, TQuizAnswerData, TQuizAnswerTag, TQuizPath, TQuizResultAnswerTags } from '@lib/core/quizzes/types';
import {
  QUIZ_SLUG_REPLACEMENT,
  QUIZ_TYPE_EXPERT,
  QUIZ_TYPE_MULTI_PRODUCT,
  QUIZ_TYPE_RECIPE,
  QUIZ_TYPE_SITUATIONAL,
  QUIZ_TYPE_TASTE,
  QUIZ_TYPE_TASTE_ECOMMERCE,
  QUIZ_TYPE_TASTE_LOCAL,
  QUIZ_TYPE_TASTE_SHORT_RECOMMENDER,
  URL_QUIZ_TYPE_TASTE_REMOTE,
} from '@lib/core/quizzes/utils/consts';
import {
  isAnswerTagProductCategoryContext,
  isQuizForProductCategory,
  isQuizSlugUserPreference,
  isQuizSlugWithLocale,
  isQuizTypeMultiProduct,
  isQuizTypeTaste,
} from '@lib/core/quizzes/utils/filters';
import { selectRetailerSlug } from '@lib/core/retailers/selectors/retailer';
import { selectAppliedDesignSet } from '@lib/core/retailers/selectors/retailerLocation';
import {
  DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM,
  RETAILER_SLUG_FOR_REPLACEMENT,
  isApplicationKiosk,
} from '@lib/core/service/consts';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import {
  DIETARY_PREFERENCE_SLUG,
  SITUATIONAL_PRESELECTED_PRODUCT_CATEGORY_URL_PARAM,
  SITUATIONAL_PRESELECTED_URL_PARAM,
} from '@lib/tools/shared/helpers/consts';
import { isQuizAnswerTagContextReferenceProduct } from '@lib/tools/shared/pmi/quizzes/filters';
import { CRAFTED } from '@lib/tools/shared/pmi/retailers/consts';
import { QUIZ_SLUG_PREFERENCE } from '@lib/tools/shared/utils/quizzes/consts';

/**
 * * Toolkit for handy filters and transformations of `quizzes`, `quiz` and `userQuiz` state data.
 *
 * 3. `getPreferredQuizSlug(choices: string[]): string`: Determines the preferred quiz slug based on user choices.
 * 5. `parseResultQuizAnswerTags(answerTags: TQuizQuestionAnswerTags): TQuizAnswerTag[]`:
 */
class QuizUtils {
  /**
   * Fetches and filters quizzes based on quiz type and additional search param criteria.
   * @param quizType
   * @returns `quiz` obtained by filtering the list of quizzes by `quiz_type`.
   */
  public static getQuiz(quizType: string): TQuiz {
    const state = store.getState();

    const quizzes = selectQuizzes(state);
    const retailerSlug = selectRetailerSlug(state);
    const productCategory = selectServiceProductCategory(state);

    if (!quizzes) return null;

    let filteredQuizzes = quizzes.filter(quiz => quiz.quiz_type?.slug === quizType);

    // ? Exception for Crafted quiz
    if (productCategory === CRAFTED) filteredQuizzes = quizzes.filter(isQuizForProductCategory);

    const { search } = window.location;
    const disableUserCharacterUrlParam = !!new URLSearchParams(search).get(DISABLE_USER_CHARACTER_TOGGLE_URL_PARAM);

    switch (quizType) {
      case QUIZ_TYPE_MULTI_PRODUCT:
        filteredQuizzes = filteredQuizzes.filter(isQuizSlugUserPreference);
        break;
      case QUIZ_TYPE_TASTE:
        if (!disableUserCharacterUrlParam) {
          filteredQuizzes = filteredQuizzes.filter(isQuizSlugUserPreference);
        }
        filteredQuizzes = filteredQuizzes.filter(isQuizForProductCategory);
        break;
      case QUIZ_TYPE_EXPERT:
      case QUIZ_TYPE_RECIPE:
        filteredQuizzes = filteredQuizzes.filter(isQuizForProductCategory);
        break;
      case QUIZ_TYPE_SITUATIONAL:
        break;
      case URL_QUIZ_TYPE_TASTE_REMOTE:
        filteredQuizzes = quizzes.filter(isQuizForProductCategory);
        filteredQuizzes = filteredQuizzes.filter(quiz => quiz.quiz_type?.slug === QUIZ_TYPE_TASTE_LOCAL);
        break;
      case QUIZ_TYPE_TASTE_LOCAL:
      case QUIZ_TYPE_TASTE_ECOMMERCE:
      case QUIZ_TYPE_TASTE_SHORT_RECOMMENDER:
        filteredQuizzes = filteredQuizzes.filter(isQuizForProductCategory);

        if (retailerSlug === 'switzerland') {
          if (filteredQuizzes.length) {
            // Switzerland has quizzes for de, it, en, and fr.
            filteredQuizzes = filteredQuizzes.filter(isQuizSlugWithLocale);
          }
        }
        break;
      default:
        break;
    }

    if (!filteredQuizzes.length) {
      console.warn('Quiz not found for `quizType`', quizType);
    }

    return filteredQuizzes?.[0];
  }

  /**
   * @param apiUrl
   * @returns quizzes api url with retailer and user quiz slug.
   */
  public static changeApiUrlForQuiz(apiUrl): string {
    const retailerSlug = selectRetailerSlug(store.getState());
    const userQuizSlug = selectUserQuizSlug(store.getState());

    return (
      apiUrl.replace(RETAILER_SLUG_FOR_REPLACEMENT, retailerSlug).replace(QUIZ_SLUG_REPLACEMENT, userQuizSlug) || ''
    );
  }

  /**
   * @param preferences
   * @returns the quiz slug based on user preferences.
   */
  public static getPreferredQuizSlug = (preferences: string[]) => {
    /**
     * No Preferences	        no-preferences
     * No Dairy	              no-diary
     * No Meat	              no-meat-no-gluten
     * No Gluten              no-meat-no-gluten
     * No Meat, No Gluten	    no-meat-no-gluten
     * No Dairy, No Meat	    all-preferences
     * No Dairy, No Gluten	  all-preferences
     * All	                  all-preferences
     */
    let slug = '';
    switch (true) {
      case [DIETARY_PREFERENCE_SLUG.NO_MEAT, DIETARY_PREFERENCE_SLUG.NO_DAIRY].every(el => preferences.includes(el)) &&
        !QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES):
      case [DIETARY_PREFERENCE_SLUG.NO_GLUTEN, DIETARY_PREFERENCE_SLUG.NO_DAIRY].every(el =>
        preferences.includes(el),
      ) && !QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES):
      case [DIETARY_PREFERENCE_SLUG.NO_MEAT, DIETARY_PREFERENCE_SLUG.NO_GLUTEN, DIETARY_PREFERENCE_SLUG.NO_DAIRY].every(
        el => preferences.includes(el),
      ) && !QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES):
        slug = QUIZ_SLUG_PREFERENCE.NO_MEAT_NO_GLUTEN;
        break;
      case [DIETARY_PREFERENCE_SLUG.NO_MEAT, DIETARY_PREFERENCE_SLUG.NO_DAIRY].every(el => preferences.includes(el)):
      case [DIETARY_PREFERENCE_SLUG.NO_GLUTEN, DIETARY_PREFERENCE_SLUG.NO_DAIRY].every(el => preferences.includes(el)):
      case [DIETARY_PREFERENCE_SLUG.NO_MEAT, DIETARY_PREFERENCE_SLUG.NO_GLUTEN, DIETARY_PREFERENCE_SLUG.NO_DAIRY].every(
        el => preferences.includes(el),
      ):
      case [DIETARY_PREFERENCE_SLUG.NO_GLUTEN, DIETARY_PREFERENCE_SLUG.NO_MEAT].some(el => preferences.includes(el)) &&
        !QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.NO_MEAT_NO_GLUTEN):
      case [DIETARY_PREFERENCE_SLUG.NO_DAIRY].every(el => preferences.includes(el)) &&
        !QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.NO_DIARY):
        slug = QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES;
        break;
      case [DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES].every(el => preferences.includes(el)):
        slug = QUIZ_SLUG_PREFERENCE.NO_PREFERENCES;
        break;
      case [DIETARY_PREFERENCE_SLUG.NO_GLUTEN, DIETARY_PREFERENCE_SLUG.NO_MEAT].some(el => preferences.includes(el)) &&
        QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.NO_MEAT_NO_GLUTEN):
        slug = QUIZ_SLUG_PREFERENCE.NO_MEAT_NO_GLUTEN;
        break;
      case [QUIZ_SLUG_PREFERENCE.NO_DIARY].every(el => preferences.includes(el)) &&
        QuizUtils.isTasteQuizSpecificPreferencesAvailable(QUIZ_SLUG_PREFERENCE.NO_DIARY):
        slug = QUIZ_SLUG_PREFERENCE.NO_DIARY;
        break;
      default:
        slug = QUIZ_SLUG_PREFERENCE.NO_PREFERENCES;
        break;
    }
    return slug;
  };

  public static getTasteQuizSlugPreferences = (preferences: string) => {
    /**
     * No Preferences	        no-preferences
     * No Dairy	              no-diary
     * No Meat	              no-meat-no-gluten
     * No Gluten              no-meat-no-gluten
     * All	                  all-preferences
     */
    let slug = '';
    switch (true) {
      case preferences.includes(DIETARY_PREFERENCE_SLUG.NO_FOOD_PREFERENCES):
        slug = QUIZ_SLUG_PREFERENCE.NO_PREFERENCES;
        break;
      case preferences.includes(QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES):
        slug = QUIZ_SLUG_PREFERENCE.ALL_PREFERENCES;
        break;
      case preferences.includes(DIETARY_PREFERENCE_SLUG.NO_GLUTEN):
      case preferences.includes(DIETARY_PREFERENCE_SLUG.NO_MEAT):
        slug = QUIZ_SLUG_PREFERENCE.NO_MEAT_NO_GLUTEN;
        break;
      case preferences.includes(QUIZ_SLUG_PREFERENCE.NO_DIARY):
        slug = QUIZ_SLUG_PREFERENCE.NO_DIARY;
        break;
      default:
        slug = QUIZ_SLUG_PREFERENCE.NO_PREFERENCES;
        break;
    }
    return slug;
  };

  public static isTasteQuizSpecificPreferencesAvailable(quizSlug: string): boolean {
    const state = store.getState();

    const quizzes = selectQuizzes(state);
    const productCategory = selectServiceProductCategory(state);
    const { isDesignSetVinhoodApp } = selectAppliedDesignSet(state);

    const tasteQuizAvailable = isDesignSetVinhoodApp
      ? quizzes.filter(isQuizTypeMultiProduct)
      : quizzes
          .filter(quiz => quiz.product_categories.some(category => category.name === productCategory))
          .filter(isQuizTypeTaste);
    return tasteQuizAvailable?.some(quiz => quiz.slug.includes(quizSlug));
  }

  /**
   * Recursively goes through all possible paths of the quiz graph and returns a KVP of questions along with their
   * max depths. Used for progress bar computation and tree traversal algorithms.
   *
   * @param quizPath
   */
  public static getQuestionsDepths(quizPath: TQuizPath) {
    const questionsDepths = {};

    // Returns a KVP of Questions and associated QuestionPools.
    const parseQuestionPools = () => {
      const questionPools = {};

      // Obtain a unique list of QuestionPools for each question.
      Object.keys(quizPath).forEach(question => {
        const { answers } = quizPath[question];
        const questionPool: any[] = [];
        Object.keys(answers).forEach(answer => {
          if (answers[answer].question_pool) questionPool.push(answers[answer].question_pool);
        });

        // Merge and deduplicate the QuestionPool
        // eslint-disable-next-line prefer-spread
        const deduplicatedPool = Array.from(new Set([].concat.apply([], questionPool)).values());

        if (!questionPools[question] && questionPool.length) questionPools[question] = deduplicatedPool;
        else questionPools[question] = null;
      });

      return questionPools;
    };

    // Recursive function that computes depth of a given key in a tree.
    const computeDepths = (questionPools, questionId) => {
      Object.keys(questionPools).forEach(question => {
        if (!questionPools[question].length) {
          questionsDepths[question] = 0;
        } else if (questionPools[question].includes(questionId)) {
          // Get all keys that contain the `questionKey` as value.

          // Increase depth of these questions, accounting for multiple questions in the pool.
          const questionPool = questionPools[question];
          let maxDepthInPool = 0;

          questionPool.forEach(questionInPool => {
            if (maxDepthInPool < questionsDepths[questionInPool]) maxDepthInPool = questionsDepths[questionInPool];
          });

          questionsDepths[question] = maxDepthInPool + 1;

          computeDepths(questionPools, question);
        }
      });
    };

    const questionPools = parseQuestionPools();

    // Start computing the depth from a leaf node
    Object.keys(questionPools).forEach(question => {
      if (!questionPools[question].length) computeDepths(questionPools, question);
    });

    // Reverse the key value pairs for ease of use.
    return questionsDepths;
  }

  /**
   * ! API improvements needed to conform state data. TQuizResultAnswerTags -> TQuizAnswerTag[]
   */
  public static parseResultQuizAnswerTags(answerTags: TQuizResultAnswerTags): TQuizAnswerTag[] {
    const quizAnswerTags = new Set([]);

    Object.values(answerTags).forEach(question => {
      Object.values(question).forEach(qat => {
        quizAnswerTags.add(JSON.stringify(qat));
      });
    });

    return [...quizAnswerTags].map(qat => JSON.parse(qat));
  }

  /**
   * @returns a list of answerIds to pre-submit upon quiz mount
   */
  public static getPreselectedAnswerIds = () => {
    const state = store.getState();
    const searchParams = new URLSearchParams(window.location.search);

    const quizViewQuestionData = selectQuizViewQuestionData(state);
    if (!quizViewQuestionData) {
      return [];
    }

    const preselectedAnswersFromUrl = searchParams.get(SITUATIONAL_PRESELECTED_URL_PARAM);
    if (preselectedAnswersFromUrl) {
      return preselectedAnswersFromUrl.split(',') || [];
    }

    const productCategory = selectServiceProductCategory(state);
    const preselectedProductCategoryFromUrl = searchParams.get(SITUATIONAL_PRESELECTED_PRODUCT_CATEGORY_URL_PARAM);
    const currentProductCategory = isApplicationKiosk ? productCategory : preselectedProductCategoryFromUrl || '';

    const preselectedAnswer = quizViewQuestionData.answers.find(answer => {
      const { tags } = Object.values(answer)[0];
      return tags?.[0]?.name === currentProductCategory && isAnswerTagProductCategoryContext(tags[0]);
    });

    return preselectedAnswer ? [Object.keys(preselectedAnswer)[0]] : [];
  };

  /**
   * @returns reference product data based on quiz answer tags
   */
  public static getReferenceProductData = () => {
    const state = store.getState();

    const quizViewAnswerTags = selectQuizViewAnswerTags(state);
    const quizData = selectQuizData(state);

    const quizAnswerReferenceProduct = quizViewAnswerTags.find(isQuizAnswerTagContextReferenceProduct);

    let selectedAnswer: TQuizAnswerData;

    if (quizAnswerReferenceProduct) {
      Object.values(quizData).forEach(questionData => {
        questionData.answers?.forEach(answer => {
          const answerId = Object.keys(answer)[0];

          if (
            answer[answerId]?.tags.some(
              tag => tag.name === quizAnswerReferenceProduct.name && tag.context === quizAnswerReferenceProduct.context,
            )
          ) {
            selectedAnswer = answer;
          }
        });
      });
    }

    return selectedAnswer || null;
  };

  /**
   * @returns the current progress percentage of the userQuiz based on the maximum number of questions
   * and the current question's depth in the quizData graph
   */
  public static getProgressPercentage = () => {
    const state = store.getState();

    const questionDepths = selectQuizViewQuestionDepths(state);
    const quizViewDepth = selectQuizViewDepth(state);

    if (!questionDepths) return 0;

    const maxQuestionDepth = Math.max(...Object.values(questionDepths)) + 1;

    return ((maxQuestionDepth - quizViewDepth) / maxQuestionDepth) * 100;
  };
}

export default QuizUtils;

// ? Move these to a better place.
// * Product Categories
export const SENTIA = 'sentia';
export const LEVIA = 'levia';
export const TEREA = 'terea';
export const HEETS = 'heets';
export const VEEV = 'veev';
export const CRAFTED = 'crafted';

// Nicotine Lewel
export const HIGHNICOTINECIGARETTES = 'high-nicotine-cigarettes';
export const LOWNICOTINECIGARETTES = 'low-nicotine-cigarettes';
export const TOBEDISCONTINUED = 'to-be-discontinued';
export const DISCONTINUED = 'discontinued';
export const AVAILABLE = 'available';

// Technical Caption Icon
export const THUMB = 'thumb';
export const HEART = 'heart';
export const STAR = 'star';
export const TRENDING = 'trending';
export const DUTY_FREE_RETAILER_SLUGS = ['italy', 'japan'];

import { useSelector } from 'react-redux';

import { TProductCategory } from '@lib/core/products/types';
import {
  selectAppPreviousRoute,
  selectAppRoute,
  selectFidelityCardIdFromKiosk,
  selectIsDevToolsEnabled,
  selectIsHostMocked,
  selectIsLocaleEditMode,
  selectIsSplashScreenPageShown,
  selectIsTasteIdGuideShown,
  selectIsTastePathOnboardingShown,
  selectIsThirdPartyConnectModalShown,
  selectLocale,
  selectServiceProductCategory,
  selectShouldHideDownloadAppCard,
  selectShouldShowScanBarcode,
  selectShouldShowTastePathStartPage,
  selectThirdPartyConnectModalScenario,
} from '@lib/core/service/selectors';
import { ThirdPartyConnectModalShown } from '@lib/core/service/types/interface';
import { languages } from '@lib/tools/locale/utils/consts';

type IService = {
  isSplashScreenPageShown: boolean;
  isTastePathOnboardingShown: boolean;
  isTasteIdGuideShown: boolean;
  isDevToolsEnabled: boolean;
  isHostMocked: boolean;
  isLocaleEditMode: boolean;
  isLayoutRightToLeft: boolean;
  locale: string;
  previousRoute: string;
  route: string;
  productCategory: TProductCategory;
  shouldShowTastePathStartPage: boolean;
  shouldHideDownloadAppCard: boolean;
  shouldShowScanBarcode: boolean;
  scenarioForThirdPartyConnectModalOpened: {
    scenario: string;
    productName: string;
  };
  isThirdPartyConnectModalShown: ThirdPartyConnectModalShown;
  fidelityCardIdFromKiosk: string;
};

/**
 * * Accessible features for views and components
 * ? App state can be structured as core/utils/* modules
 * {
 *   locale: { 
//  *     code: boolean; // en/it/...
//  *     isRtl: boolean;
//  *     editMode: boolean;
 *   },
 *   analytics: { 
 *     code: boolean; // en/it/...
 *   },
 *   testing: {  // ?
 *     code: boolean; // en/it/...
 *   },
 *   route: {
//  *     previousRoute: string;
//  *     route: string;
 *   },
 *   rooms: { // ? Converge with IServiceInstance or IUserRoom?
//  *     isEnabled: string;
//  *     productCategory: TProductCategory;
//  *     retailerLocationId: string;
//  *     retailerSlug: string;
 *   },
 *   devtools: {
//  *     isEnabled: string;
//  *     route: string;
 *   },
 *   user: {
 *     tastePath: {
//  *     isFirstVisit: boolean;
//  *     showHints: boolean;
 *     },
 *   },
 *   service: { // ? Converge with Launcher namespace and build IService. Pass context to components.
//  *     productCategory: TProductCategory;
//  *     terms: TProductCategory;
//  *     geolocation: any;
 *   },
 * }
 */
export const useApp = (): IService => {
  const locale = useSelector(selectLocale);

  const isLocaleRtl = () => {
    const rtlLanguages = [languages.ARABIC, languages.HEBREW];
    return rtlLanguages.some(language => locale.startsWith(language));
  };
  return {
    fidelityCardIdFromKiosk: useSelector(selectFidelityCardIdFromKiosk),
    isDevToolsEnabled: useSelector(selectIsDevToolsEnabled),
    isHostMocked: useSelector(selectIsHostMocked),
    isLayoutRightToLeft: isLocaleRtl(),
    isLocaleEditMode: useSelector(selectIsLocaleEditMode),
    isSplashScreenPageShown: useSelector(selectIsSplashScreenPageShown),
    isTasteIdGuideShown: useSelector(selectIsTasteIdGuideShown),
    isTastePathOnboardingShown: useSelector(selectIsTastePathOnboardingShown),
    isThirdPartyConnectModalShown: useSelector(selectIsThirdPartyConnectModalShown),
    locale,
    previousRoute: useSelector(selectAppPreviousRoute),
    productCategory: useSelector(selectServiceProductCategory),
    route: useSelector(selectAppRoute),
    scenarioForThirdPartyConnectModalOpened: useSelector(selectThirdPartyConnectModalScenario),
    shouldHideDownloadAppCard: useSelector(selectShouldHideDownloadAppCard),
    shouldShowScanBarcode: useSelector(selectShouldShowScanBarcode),
    shouldShowTastePathStartPage: useSelector(selectShouldShowTastePathStartPage),
  };
};

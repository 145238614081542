import { createSlice } from '@reduxjs/toolkit';

import { bannersApiUrlCreator } from '@lib/core/banners/slices/urls';
import { TBanner } from '@lib/core/banners/types';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';

interface IState {
  data: TBanner[];
  error: string;
  isLoading: boolean;
}

const initialState: IState = {
  data: [],
  error: '',
  isLoading: false,
};

export const actionGetBanners = createTypedAsyncThunk<TBanner[], { characterTypeSlug: string }>(
  'actionGetBanners',
  async ({ characterTypeSlug }) => {
    const { results = [] } = await request(bannersApiUrlCreator({ characterTypeSlug }));
    return results;
  },
);

export const bannersSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetBanners.pending, state => {
      state.error = '';
      state.isLoading = true;
    });
    builder.addCase(actionGetBanners.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(actionGetBanners.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
  },
  initialState,
  name: 'banners',
  reducers: {},
});

export default bannersSlice.reducer;

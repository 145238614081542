import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@lib/core/service/types/appStateType';

const selectProductProductRateState = (state: RootState) => state.users.productRate;

const selectProductRateData = createSelector([selectProductProductRateState], state => state.data);

const selectProductRateOptions = createSelector([selectProductProductRateState], state => state.options);

const selectIsProductRateDataLoading = createSelector([selectProductProductRateState], state => state.isDataLoading);
const selectIsProductRateUpdateLoading = createSelector(
  [selectProductProductRateState],
  state => state.isUpdateRateLoading,
);

const selectLastUpdatedProductProductRateId = createSelector(
  [selectProductProductRateState],
  state => state.lastUpdatedProductRatingId,
);

export {
  selectProductRateData,
  selectLastUpdatedProductProductRateId,
  selectIsProductRateDataLoading,
  selectProductRateOptions,
  selectIsProductRateUpdateLoading,
};

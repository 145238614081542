import { FC, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { actionGetRetailerCharacterDetails } from '@lib/core/characters/slices';
import { useProducts } from '@lib/core/products/hooks/products';
import { actionResetCorrelatedProducts, getProductsByCharacterCorrelation } from '@lib/core/products/slices/products';
import { useQuizView, useUserQuiz, useUserQuizType } from '@lib/core/quizzes/hooks';
import { actionPatchUserQuizMetadata } from '@lib/core/quizzes/slices';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { isProductSentia } from '@lib/tools/shared/pmi/products/filters';
import { recommendProducts } from '@lib/tools/shared/pmi/products/recommender';
import {
  isQuizAnswerTagDissatisfied,
  isQuizAnswerTagRecommendSentia,
  isQuizAnswerTagSatisfied,
  isQuizAnswerTagUserSegment,
  isResultLimitedCharacter,
} from '@lib/tools/shared/pmi/quizzes/filters';
import { HEART, HEETS, STAR, TEREA, THUMB, TRENDING, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import Spinner from '@components/pmi/src/common/Spinner';
import { TechnicalCaptionIcon } from '@components/pmi/src/organisms/Results/RecommendedProduct/RecommendedProduct';
import { LoadingPage } from '@components/pmi/src/templates/LoadingPage/LoadingPage';
import QuizResultV2 from '@components/pmi/src/templates/Result/ResultPage';
import { checkCurrentPrimaryColor, isOpenIntegration, isWyngEcommerce, navigateHome } from '@components/pmi/src/utils';

const QuizResult: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { productCategory } = useApp();
  const { retailerTags } = useRetailer();
  const { isRetailerLocationStoreTypeEcommerce } = useRetailerLocation();
  const { isUserQuizTypeShortRecommender } = useUserQuizType();
  const {
    isAromaNoteSecondaryAddon,
    isAddToCartAddon,
    isAromaNotesDisabledAddon,
    isEssenceAromaDisabledAddon,
    isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabledAddon,
    isEnableBuyEcommerceAddon,
    isEnableTereaBannerAddon,
    isEnableVeevBannerAddon,
    isEnableLeviaBannerAddon,
    isRestrictedProductCardsAddon,
    isRestrictedResultPageAddon,
    isTereaHideEssencesAddon,
    isTobaccoExperienceHiddenAddon,
    isProductLongDescriptionHiddenAddon,
    isProductShortDescriptionHiddenAddon,
    isHealthWarningFooterAddon,
    isMentholDisclaimerAddon,
    isHealthWarningFooterToIsraelAddon,
    isResultLongDisclaimerAddon,
    isRestrictedRecommendationAddon,
  } = useAddons();
  const { isDesignSetPmiV2 } = useRetailerDesignSet();
  const { userQuizId, userQuizCharacters } = useUserQuiz();
  const { quizViewAnswerTags } = useQuizView();
  const { productsByCharacterCorrelation, isProductsLoading } = useProducts();

  const currentPrimaryColor = checkCurrentPrimaryColor();

  const isResultLimitedCharacterCondition = quizViewAnswerTags.some(isResultLimitedCharacter);
  const isUserSatisfied = quizViewAnswerTags.some(isQuizAnswerTagSatisfied);
  const isUserDissatisfied = quizViewAnswerTags.some(isQuizAnswerTagDissatisfied);
  const isUserSegmentTagPresent = quizViewAnswerTags.some(isQuizAnswerTagUserSegment);
  const isRecommendSentia = quizViewAnswerTags.some(isQuizAnswerTagRecommendSentia);
  const isSentiaProductAvailable = productsByCharacterCorrelation.some(isProductSentia);

  const tereaProducts = useMemo(
    () => productsByCharacterCorrelation.filter(gprl => !isProductSentia(gprl)),
    [productsByCharacterCorrelation],
  );

  const recommendedGprls = useMemo(
    () =>
      isSentiaProductAvailable && !isRecommendSentia
        ? recommendProducts(tereaProducts)
        : recommendProducts(productsByCharacterCorrelation),
    [isSentiaProductAvailable, isRecommendSentia, tereaProducts, productsByCharacterCorrelation],
  );

  const isThreeProductsShown = recommendedGprls?.length === 3;

  let technicalCaptionIcon: TechnicalCaptionIcon[] | [] = [];
  let technicalCaptionTextNumbers: number[] | [] = [];
  if (isThreeProductsShown) {
    technicalCaptionIcon = [HEART, STAR, TRENDING];
    technicalCaptionTextNumbers = [3, 4, 5];
  } else if (isUserSatisfied) {
    technicalCaptionIcon = [HEART, THUMB];
    technicalCaptionTextNumbers = [2, 1];
  } else if (isUserDissatisfied) {
    technicalCaptionIcon = [THUMB, HEART];
    technicalCaptionTextNumbers = [1, 2];
  }

  const isTechnicalCaptionEnable =
    (((isUserSatisfied || isUserDissatisfied) && !isThreeProductsShown) ||
      (isUserSegmentTagPresent && isThreeProductsShown)) &&
    !isResultLimitedCharacterCondition;
  const isBackgroundEnabled = productCategory === TEREA;
  const isVeev = productCategory === VEEV;
  const isShowHealthWarningFooter =
    (!isRetailerLocationStoreTypeEcommerce || (isRetailerLocationStoreTypeEcommerce && isHealthWarningFooterAddon)) &&
    !isHealthWarningFooterToIsraelAddon;

  const userQuizCharacter = userQuizCharacters[0];
  const characterId = userQuizCharacter?.identifier;
  const characterHeaderCaption = userQuizCharacter?.character_caption;
  const characterHeaderDescription = userQuizCharacter?.character_description;
  const characterHeaderImage = userQuizCharacter?.image;
  const characterHeaderName = userQuizCharacter?.name;

  const sendProps = {
    characterHeaderCaption,
    characterHeaderDescription,
    characterHeaderImage,
    characterHeaderName,
    gprls: isSentiaProductAvailable && !isRecommendSentia ? tereaProducts : productsByCharacterCorrelation,
    isAddToCartAddon,
    isAromaNoteSecondaryAddon,
    isAromaNotesDisabledAddon,
    isBackgroundEnabled,
    isEnableBuyEcommerceAddon,
    isEnableLeviaBannerAddon,
    isEnableTereaBannerAddon,
    isEnableVeevBannerAddon,
    isEssenceAromaDisabledAddon,
    isEssenceBodyDisabledAddon,
    isEssenceCoolingDisabledAddon,
    isHealthWarningFooterToIsraelAddon,
    isMentholDisclaimerAddon,
    isOpenIntegration: isOpenIntegration(),
    isProductLongDescriptionHiddenAddon,
    isProductShortDescriptionHiddenAddon,
    isRestrictedProductCardsAddon,
    isRestrictedRecommendationAddon,
    isRestrictedResultPageAddon,
    isResultLongDisclaimerAddon,
    isSentiaEnabled: isRecommendSentia && isSentiaProductAvailable,
    isShowHealthWarningFooter,
    isTechnicalCaptionEnable,
    isTereaHideEssencesAddon,
    isThreeProductsShown,
    isTobaccoExperienceHiddenAddon,
    isUserQuizTypeShortRecommender,
    isVeev,
    maxQuantity: retailerTags?.ecommerceQuantityRange?.[1],
    minQuantity: retailerTags?.ecommerceQuantityRange?.[0],
    noCharacter: isResultLimitedCharacterCondition,
    recommendedGprls,
    resultPageBackgroundImage:
      'https://media-staging.taste-advisor-vh.com/media/product_attributes/v2backgrounds/v2_terea_quiz_background.png',
    selectedProductCategory: productCategory,
    sessionId: userQuizId,
    startOver: () => navigateHome(dispatch),
    technicalCaptionIcon,
    technicalCaptionTextNumbers,
  };

  useEffect(() => {
    if (characterId) {
      if (!productsByCharacterCorrelation.length) dispatch(getProductsByCharacterCorrelation({ characterId }));

      dispatch(actionGetRetailerCharacterDetails({ characterId })).then(data => {
        if (!data) navigate(prependBasename(PAGES.error.notFound));
      });
    } else {
      navigate(prependBasename(PAGES.error.notFound));
    }

    return () => {
      dispatch(actionResetCorrelatedProducts());
    };
  }, [characterId]);

  useEffect(() => {
    if (recommendedGprls?.length) {
      const recommendedProductIds = recommendedGprls.map(gprl => gprl.product.identifier);

      const payload = {
        metadata: {
          result_recommended_products: recommendedProductIds,
        },
      };

      // Insert recommended products info into the userQuiz metadata.
      // Example:
      // {
      //   "metadata": {
      //     "result_recommended_products": ["IT001", "IT002", "IT003"]
      //   }
      // }
      dispatch(actionPatchUserQuizMetadata(payload));
    }
  }, [recommendedGprls]);

  if (
    ((isDesignSetPmiV2 && !isRetailerLocationStoreTypeEcommerce) ||
      (isRetailerLocationStoreTypeEcommerce && !isWyngEcommerce())) &&
    !isProductsLoading
  ) {
    return <QuizResultV2 {...sendProps} />;
  }

  if (isDesignSetPmiV2 && (isProductsLoading || (productsByCharacterCorrelation.length && isWyngEcommerce()))) {
    return (
      <LoadingPage
        isShowBeforeResult
        currentPrimaryColor={currentPrimaryColor}
        isHeetsBackgroundEnabled={productCategory === HEETS}
      />
    );
  }

  if (isProductsLoading || productsByCharacterCorrelation[0] === undefined) {
    return <Spinner />;
  }

  return <Spinner />;
};

export default QuizResult;

import axios from 'axios';
import axiosRetry from 'axios-retry';

import { selectAppliedAddOns } from '@lib/core/retailers/selectors/retailerLocation';
import { ENV_BACKEND_DOMAIN, REQUEST_METHODS } from '@lib/core/service/consts';
import { getControllerSignal } from '@lib/core/service/requests/abortController';
import backendApiUrls from '@lib/core/service/requests/backend_api_urls';
import { selectLocale } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { checkStatus } from '@lib/core/service/utils';
import { selectAuthToken, selectUserRefreshToken } from '@lib/core/users/selectors/auth';
import { selectProfileId } from '@lib/core/users/selectors/profile';
import { actionResetAuthSlice, actionSaveAccessToken } from '@lib/core/users/slices/auth';
import { actionResetUserProfile, actionSetUserProfileId } from '@lib/core/users/slices/profile';
import { parseError } from '@lib/tools/shared/helpers';

// ! Improve typing on the config object.
// type TRequestConfig = {
//   additionalHeaders?: any;
//   method?: string;
//   ignoreProfileHeaders?: boolean;
//   isAuth?: boolean;
// };

axiosRetry(axios, { retries: 3 });

interface IRequestWithKeysOptions {
  apiPath: string;
  body?: Record<string, any>;
  config?: {
    method: (typeof REQUEST_METHODS)[keyof typeof REQUEST_METHODS];
  };
  withErrorReturn?: boolean;
}
const request = (apiPath, config: any = {}, body: any = {}, withErrorReturn = false) => {
  const language = selectLocale(store.getState());
  const requestConfig: any = {
    ...config,
  };

  const ignoreProfileHeaders = requestConfig.ignoreProfileHeaders || false;
  const profileId = selectProfileId(store.getState());
  const accessTokenFromStore = selectAuthToken(store.getState());

  requestConfig.method = config?.method || REQUEST_METHODS.GET;
  requestConfig.isAuth = apiPath.includes('jgz') ? '' : config.isAuth || accessTokenFromStore;

  const requestHeaders: Record<string, any> = {
    'Accept-Language': language,
    'Content-Type': 'application/json',
    ...(profileId && !ignoreProfileHeaders ? { 'x-profile': profileId } : {}),
    ...config.additionalHeaders,
  };
  if (requestConfig.isAuth) {
    requestHeaders.Authorization = `Bearer ${requestConfig.isAuth}`;
  }

  const requestToServer =
    requestConfig.method === REQUEST_METHODS.GET
      ? axios({
          baseURL: ENV_BACKEND_DOMAIN,
          headers: requestHeaders,
          method: requestConfig.method,
          params: requestConfig.params,
          signal: getControllerSignal(),
          url: apiPath,
        })
      : axios({
          baseURL: ENV_BACKEND_DOMAIN,
          data: JSON.stringify(body),
          headers: requestHeaders,
          method: requestConfig.method,
          signal: getControllerSignal(),
          url: apiPath,
        });

  const requestWithToken = requestToServer.then(
    response => response,
    error => {
      // ToDo is possible to inject this logic inside RTK, but it required additional setup and can be postponed
      // Idea is to handle every action reject from auth slice and save this as error. Then in some useEffect we can do
      // logic based on error status === 401
      // Also this file should be refactored and i have some ideas, since i started but postponed it due as
      // requiring additional time
      const state = store.getState();
      const authToken = selectAuthToken(state);
      const refreshToken = selectUserRefreshToken(state);
      if (axios.isCancel(error)) {
        // ToDo this rejection may lead to unexpected behavior in scope of RTK thunks error handling
        return Promise.reject(error);
      }

      const { status, config: errorResponseConfig } = error.response;
      const { isNoEmailAddon } = selectAppliedAddOns(state);

      const isNoEmailAddonError = isNoEmailAddon && errorResponseConfig?.url?.includes('send_confirm_email');

      if (status === 401 && refreshToken && authToken === requestConfig.isAuth && !isNoEmailAddonError) {
        return axios({
          baseURL: ENV_BACKEND_DOMAIN,
          data: {
            refresh: refreshToken,
          },
          headers: requestHeaders,
          method: REQUEST_METHODS.POST,
          url: backendApiUrls.refreshTokenUrl,
        })
          .then(response => {
            const newAccessToken = response?.data?.access;
            if (newAccessToken) {
              store.dispatch(actionSaveAccessToken(newAccessToken));
              return request(
                apiPath,
                {
                  ...config,
                  isAuth: newAccessToken,
                },
                body,
              );
            }
            if (authToken) {
              return store.dispatch(actionResetAuthSlice());
            }
            return response;
          })
          .catch(e => {
            if (
              authToken &&
              (e.response?.config?.url === backendApiUrls.refreshTokenUrl || e.response?.status === 401)
            ) {
              return store.dispatch(actionResetAuthSlice());
            }
            return parseError(e.response);
          });
      }

      if (withErrorReturn) {
        return {
          ...error.response,
        };
      }
      return Promise.reject(parseError(error.response));
    },
  );

  return requestWithToken
    .then(response => {
      if (!ignoreProfileHeaders) {
        const responseHeadersProfileId = response.headers?.['x-profile'];
        if (responseHeadersProfileId && responseHeadersProfileId !== profileId) {
          store.dispatch(actionSetUserProfileId(responseHeadersProfileId));
        } else if (response.headers && !responseHeadersProfileId) {
          store.dispatch(actionResetUserProfile());
        }
      }
      return response.request ? checkStatus(response, withErrorReturn) : response;
    })
    .then(checkedData => (checkedData?.data ? checkedData?.data : checkedData));
};

export const requestWithKeysAsOptions = (options: IRequestWithKeysOptions) =>
  request(options.apiPath, options.config, options.body, options.withErrorReturn);

export default request;

import { selectQuizViewAnswerTags, selectUserQuizCharacters } from '@lib/core/quizzes/selectors';
import { selectAppliedAddOns } from '@lib/core/retailers/selectors/retailerLocation';
import { selectServiceProductCategory } from '@lib/core/service/selectors';
import { store } from '@lib/core/service/store';
import { isAppInIframe } from '@lib/core/service/utils';
import {
  POST_MESSAGE_ADD_TO_CART,
  POST_MESSAGE_BEST_MATCH,
  POST_MESSAGE_DOM_HEIGHT,
  POST_MESSAGE_PRODUCT_SETS,
  POST_MESSAGE_QUIZ_COMPLETED,
  POST_MESSAGE_SEND_TA_GA_EVENT,
} from '@lib/tools/comms/consts';
import { sendPostMessage } from '@lib/tools/comms/utils';
import { actionUpdateTrackingLogs } from '@lib/tools/dat/slices';
import { CRAFTED } from '@lib/tools/shared/pmi/retailers/consts';
import { PAGES } from '@lib/tools/views/urls';

import { ERROR_TA_MISSING_TRACKING_ATTRIBUTES } from '@components/pmi/src/utils/consts';

export const broadcastUserQuizResultToHost = (event, payload) => {
  const state = store.getState();

  const productCategory = selectServiceProductCategory(state);

  if (productCategory === CRAFTED) return;

  const postMessage = {};

  if (event === POST_MESSAGE_QUIZ_COMPLETED) {
    const { recommendedProductCaptions, products } = payload;
    const [quizCharacter] = selectUserQuizCharacters(state);

    /**
     * @todo send only useful tags to host
     */
    const quizAnswerTags = selectQuizViewAnswerTags(state);

    postMessage[POST_MESSAGE_QUIZ_COMPLETED] = {
      character: quizCharacter,
      products,
      quizAnswerTags,
      recommendedProductCaptions,
    };
  } else if (event === POST_MESSAGE_PRODUCT_SETS) {
    postMessage[POST_MESSAGE_PRODUCT_SETS] = { bundles: payload };
  } else if (event === POST_MESSAGE_BEST_MATCH) {
    postMessage[POST_MESSAGE_BEST_MATCH] = [payload];
  }

  sendPostMessage(postMessage);
};

export const isRouteEcommerce = () => {
  const retailerUrls = [
    PAGES.ta.quiz.local,
    PAGES.ta.quiz.ecommerce,
    PAGES.ta.result,
    PAGES.error.badRequest,
    PAGES.error.notFound,
  ];

  return retailerUrls.some(url => window.location.pathname.includes(url));
};

export const broadcastDomHeightToHost = () => {
  const frameRoot = document.getElementById('root');
  if (frameRoot && isAppInIframe) {
    // * Account for sticky footer height on mobile
    const footerElement = document.getElementsByClassName('ta-v2-quiz-footer-wrapper')?.[0];

    if (footerElement) {
      footerElement.setAttribute('style', 'position: relative;');
      const footerElementPos = footerElement.getBoundingClientRect();
      // Add an offset for some extra height.
      sendPostMessage({
        [POST_MESSAGE_DOM_HEIGHT]: footerElementPos.y + footerElementPos.height,
      });
    } else {
      // Add an offset for some extra height.
      sendPostMessage({
        [POST_MESSAGE_DOM_HEIGHT]: frameRoot.offsetHeight,
      });
    }
  }
};

export const addToEcommerceCart = (ecommerceId: string, quantity: number, optionId?: string, variantId?: string) => {
  // Send product info to add to the cart.
  // For HEETS and Terea, we set the ecommerce_id, variant_id and the option_id.
  // Veev and Crafted only require the ecommerce_id.

  const postMessage = {};
  postMessage[POST_MESSAGE_ADD_TO_CART] = {
    ecommerceID: ecommerceId,
    optionID: optionId,
    quantity,
    variantID: variantId,
  };

  sendPostMessage(postMessage);
};

export const broadcastGoogleAnalyticsEvent = (payload: Record<string, string>) => {
  const state = store.getState();

  const { isGoogleAnalyticsDisabledAddon } = selectAppliedAddOns(state);

  if (isGoogleAnalyticsDisabledAddon) return;

  if (Object.keys(payload).length) {
    const postMessage = {};
    postMessage[POST_MESSAGE_SEND_TA_GA_EVENT] = { ...payload };

    store.dispatch(actionUpdateTrackingLogs({ log: { args: payload, eventName: 'PMI Analytics', type: 'Comms' } }));
    sendPostMessage(postMessage);
  } else {
    // eslint-disable-next-line no-console
    console.warn(ERROR_TA_MISSING_TRACKING_ATTRIBUTES);
  }
};

export const saveCrmIdToLocalStorage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const crmIDValue = urlParams.get('crm_id');

  if (crmIDValue) {
    localStorage.setItem('crm_id', crmIDValue);
  }
};

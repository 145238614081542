import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { profileDataApiUrlCreator } from '@lib/core/users/slices/urls';
import { IUpdateProfileRequestBody, TProfile, TProfileOptions } from '@lib/core/users/types';
import { parseError } from '@lib/tools/shared/helpers';

export interface IProfileState {
  data: undefined | TProfile;
  options: TProfileOptions;
  isLoading: boolean;
  error: string;
}

export const initialState: IProfileState = {
  data: undefined,
  error: '',
  isLoading: false,
  options: undefined,
};

export const actionGetProfileData = createTypedAsyncThunk('actionGetProfileData', async (_, { rejectWithValue }) => {
  try {
    return await request(profileDataApiUrlCreator());
  } catch (error) {
    return rejectWithValue(parseError(error));
  }
});

export const actionUpdateProfileData = createTypedAsyncThunk(
  'users/profile/actionUpdateProfileData',
  async (requestBody: IUpdateProfileRequestBody, { rejectWithValue }) => {
    try {
      return await request(profileDataApiUrlCreator(), { method: REQUEST_METHODS.PATCH }, requestBody);
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const actionGetProfileOptionsData = createTypedAsyncThunk(
  'users/profile/actionGetProfileOptionsData',
  async (_, { rejectWithValue }) => {
    try {
      return await request(profileDataApiUrlCreator(), { method: REQUEST_METHODS.OPTIONS });
    } catch (error) {
      return rejectWithValue(parseError(error));
    }
  },
);

export const profileSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(actionGetProfileOptionsData.fulfilled, (state, { payload }) => {
      state.error = '';
      state.options = payload?.choices;
      state.isLoading = false;
    });
    builder.addMatcher(
      isAnyOf(actionUpdateProfileData.pending, actionGetProfileData.pending, actionGetProfileOptionsData.pending),
      state => {
        state.error = '';
        state.isLoading = true;
      },
    );
    builder.addMatcher(
      isAnyOf(actionUpdateProfileData.fulfilled, actionGetProfileData.fulfilled),
      (state, { payload }) => {
        state.error = '';
        state.data = payload;
        state.isLoading = false;
      },
    );
    builder.addMatcher(
      isAnyOf(actionUpdateProfileData.rejected, actionGetProfileData.rejected, actionGetProfileOptionsData.rejected),
      (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      },
    );
  },
  initialState,
  name: 'profile',
  reducers: {
    actionResetUserProfile: () => initialState,
    actionSetUserProfileId: (state, { payload }: { payload: string }) => {
      state.data = { ...state.data, identifier: payload || null };
    },
  },
});

export const { actionSetUserProfileId, actionResetUserProfile } = profileSlice.actions;

export default profileSlice.reducer;

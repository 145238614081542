import { createSlice } from '@reduxjs/toolkit';

import { TParsedProductInstance, TProductInstance } from '@lib/core/products/types';
import { REQUEST_METHODS } from '@lib/core/service/consts';
import { createTypedAsyncThunk } from '@lib/core/service/createTypedAsyncThunk';
import request from '@lib/core/service/requests/request';
import { selectProductRateData } from '@lib/core/users/selectors/productRate';
import { productRateValueDataApiUrlCreator } from '@lib/core/users/slices/urls';

export const ProductRateValues = {
  1: 10,
  2: 20,
  3: 30,
  4: 40,
  5: 50,
} as const;

export type TProductRateValue = (typeof ProductRateValues)[keyof typeof ProductRateValues];

export type TProductRate = {
  rate: {
    image: string;
    rateValue: TProductRateValue;
    slug: string;
    text: string;
  };
  product: TProductInstance;
};

export type TProductRateUpdateResponse = {
  rate: TProductRateValue;
  product: string;
};

export interface IProductRatingRawApiDataWithFullProductData {
  productRate: TProductRateValue;
  product: TParsedProductInstance;
}

export interface IProductRatingOptions {
  text: string;
  slug: string;
  rateValue: TProductRateValue;
  image: string;
}

export interface IProductRatingState {
  data: TProductRate[];
  isOptionsLoading: boolean;
  isDataLoading: boolean;
  isUpdateRateLoading: boolean;
  error: string;
  lastUpdatedProductRatingId: string;
  options: IProductRatingOptions[];
}

const initialState: IProductRatingState = {
  data: [],
  error: null,
  isDataLoading: false,
  isOptionsLoading: false,
  isUpdateRateLoading: false,
  lastUpdatedProductRatingId: '',
  options: [],
};

export const actionGetProductRateOptions = createTypedAsyncThunk<
  { choices: { rate_options: IProductRatingOptions[] } },
  void
>(
  'users/productRate/actionGetProductRateOptions',
  async () => await request(productRateValueDataApiUrlCreator(), { method: REQUEST_METHODS.OPTIONS }),
);

export const actionGetProductRateData = createTypedAsyncThunk<TProductRate[]>(
  'users/productRate/actionGetProductRateData',
  async () => {
    const fetchRates = async (url: string | null, accumulated: TProductRate[] = []): Promise<TProductRate[]> => {
      if (!url) return accumulated;
      const { results, next } = await request(url, { method: REQUEST_METHODS.GET });
      return fetchRates(next, [...accumulated, ...results]);
    };

    return fetchRates(productRateValueDataApiUrlCreator());
  },
);

export const actionUpdateProductRate = createTypedAsyncThunk<
  TProductRate | TProductRateUpdateResponse,
  { productRate: string; productId: string }
>('users/productRate/actionUpdateProductRate', async ({ productRate, productId }, { getState }) => {
  const state = getState();
  const ratingData = selectProductRateData(state);

  const isRatingExist = ratingData.some(item => item.product?.product?.identifier === productId);
  const method = isRatingExist ? REQUEST_METHODS.PATCH : REQUEST_METHODS.POST;

  return request(
    productRateValueDataApiUrlCreator(isRatingExist ? productId : ''),
    { method },
    { rate: productRate, ...(!isRatingExist && { product: productId }) },
  );
});

export const productRateSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(actionGetProductRateData.pending, state => {
        state.error = '';
      })
      .addCase(actionGetProductRateData.fulfilled, (state, action) => {
        state.isDataLoading = false;
        state.data = action.payload;
      })

      .addCase(actionUpdateProductRate.pending, state => {
        state.isUpdateRateLoading = true;
        state.error = '';
        state.lastUpdatedProductRatingId = '';
      })
      .addCase(actionUpdateProductRate.rejected, (state, action) => {
        state.isUpdateRateLoading = false;
        state.error = action.error.message;
      })
      .addCase(actionUpdateProductRate.fulfilled, (state, action) => {
        const { product, rate } = action.payload;
        state.isUpdateRateLoading = false;
        if (typeof rate === 'string') {
          state.data = state.data.map(recordedValue =>
            recordedValue.product.product.identifier === product
              ? { ...recordedValue, rate: state.options.find(rateObj => rateObj.slug === rate) } // ToDo strict TS
              : recordedValue,
          );
        } else if (typeof rate === 'object') {
          state.data = [...state.data, action.payload];
        }

        state.isDataLoading = false;
      })
      .addCase(actionGetProductRateData.rejected, (state, action) => {
        state.isDataLoading = false;
        state.error = action.error.message;
      })
      .addCase(actionGetProductRateOptions.pending, state => {
        state.isOptionsLoading = true;
      })
      .addCase(actionGetProductRateOptions.rejected, state => {
        state.isOptionsLoading = false;
      })
      .addCase(actionGetProductRateOptions.fulfilled, (state, action) => {
        state.isOptionsLoading = false;
        state.options = action.payload?.choices?.rate_options.sort((a, b) => a.rateValue - b.rateValue) || [];
      });
  },
  initialState,
  name: 'productRate',
  reducers: {},
});

export default productRateSlice.reducer;

import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { broadcastDomHeightToHost } from '@app/pmi/src/comms/utils';

import { isCurrentEssenceGroup, isEssenceAroma, isEssenceIntensity } from '@lib/core/essences/filters';
import { selectEssenceGroups } from '@lib/core/essences/selectors/groups';
import { useProducts } from '@lib/core/products/hooks/products';
import { actionResetProductFeedback } from '@lib/core/products/slices/feedback';
import { actionResetProductSets } from '@lib/core/products/slices/productSets';
import { TProduct } from '@lib/core/products/types';
import { useQuiz, useQuizView, useQuizzes, useUserQuiz } from '@lib/core/quizzes/hooks';
import { actionPauseQuizView } from '@lib/core/quizzes/slices/quizView';
import { TQuizAnswer, TQuizAnswerData } from '@lib/core/quizzes/types';
import { IQuizViewHandlers } from '@lib/core/quizzes/types/quizView';
import QuizUtils from '@lib/core/quizzes/utils';
import QuizLauncher from '@lib/core/quizzes/views/QuizLauncher';
import { useRemoteAccess } from '@lib/core/retailers/hooks/remoteAccess';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { useApp } from '@lib/core/service/hooks';
import { prependBasename } from '@lib/core/service/utils';
import { isGprlReferenceProduct, isProductPearl } from '@lib/tools/shared/pmi/products/filters';
import {
  isQuizAnswerTagContextCharacterMatch,
  isQuizAnswerTagContextMood,
  isQuizAnswerTagContextReferenceProduct,
  isQuizAnswerTagProductStateDiscontinued,
  isQuizAnswerTagShowPageBestMatch,
} from '@lib/tools/shared/pmi/quizzes/filters';
import { HEETS, TEREA, VEEV } from '@lib/tools/shared/pmi/retailers/consts';
import { useAddons, useEffectSkipFirst, useRetailerDesignSet } from '@lib/tools/views/hooks';
import { useTypedDispatch } from '@lib/tools/views/hooks/useTypedDispatch';
import { PAGES } from '@lib/tools/views/urls';

import Spinner from '@components/pmi/src/common/Spinner';
import { LoadingPage } from '@components/pmi/src/templates/LoadingPage/LoadingPage';
import QuizPageQDS8001 from '@components/pmi/src/templates/Quiz/QDS8001/QuizPage';
import QuizPageQDS8002 from '@components/pmi/src/templates/Quiz/QDS8002/QuizPage';
import QuizPageQDS8003 from '@components/pmi/src/templates/Quiz/QDS8003/QuizPage';
import QuizPageQDS8004 from '@components/pmi/src/templates/Quiz/QDS8004/QuizPage';
import QuizPageQDS8005 from '@components/pmi/src/templates/Quiz/QDS8005/QuizPage';
import QuizPageQDS8006 from '@components/pmi/src/templates/Quiz/QDS8006/QuizPage';
import QuizPageQDS8007 from '@components/pmi/src/templates/Quiz/QDS8007/QuizPage';
import QuizPageQDS8008 from '@components/pmi/src/templates/Quiz/QDS8008/QuizPage';
import QuizPageQDS8009 from '@components/pmi/src/templates/Quiz/QDS8009/QuizPage';
import QuizPageQDS8010 from '@components/pmi/src/templates/Quiz/QDS8010/QuizPage';
import QuizPageQDS8011 from '@components/pmi/src/templates/Quiz/QDS8011/QuizPage';
import { QuizPage as QuizPageQDS8012 } from '@components/pmi/src/templates/Quiz/QDS8012/QuizPage';
import { checkCurrentPrimaryColor } from '@components/pmi/src/utils';

const QuizContainer: FC<IQuizViewHandlers> = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const { isLayoutRightToLeft, productCategory } = useApp();
  const { isRetailerLocationStoreTypeEcommerce } = useRetailerLocation();
  const { isDesignSetPmiV2 } = useRetailerDesignSet();
  const { remoteAccessKey, isRemoteAccessValid } = useRemoteAccess();

  const { isQuizzesFetching } = useQuizzes();
  const { isQuizFetching, quizQuestionDesignSets, quizQuestionNumberOfChoices } = useQuiz();
  const { isUserQuizComplete, userQuizQuestionImages, userQuizType } = useUserQuiz();
  const {
    handleQuizViewGoBack,
    handleQuizViewSubmitAnswer,
    quizViewData,
    quizViewQuestionId,
    quizViewAnswerTags,
    quizViewUserPath,
    isQuizViewPaused,
    isQuizViewCompleted,
    isQuizViewBackward,
    quizViewPausedQuestionId,
    quizViewPreviousQuestionId,
    quizViewDepth,
  } = useQuizView();

  const { productsList } = useProducts();
  const {
    isTobaccoExperienceHiddenAddon,
    isHealthWarningQuizAddon,
    isRestrictedQuizPageAddon,
    isIgnoreProductSeparationAddon,
    isHealthWarningFooterToIsraelAddon,
  } = useAddons();

  const essenceGroupsData = useSelector(selectEssenceGroups);

  const [isEcommerceQuizLoaded, setIsEcommerceQuizLoaded] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<TProduct | null>();
  const [selectedAnswerProduct, setSelectedAnswerProduct] = useState<TQuizAnswer>();
  const [quizViewProgress, setQuizViewProgress] = useState(0);
  const [quizViewReferenceProduct, setQuizViewReferenceProduct] = useState<TQuizAnswerData>();

  let renderComponent: JSX.Element | null;

  const isProductCategoryHeets = productCategory === HEETS;
  const isProductCategoryTerea = productCategory === TEREA;
  const isProductCategoryVeev = productCategory === VEEV;

  const isShowRestrictedBackground = isRestrictedQuizPageAddon && isProductCategoryTerea;

  const isFetching = isQuizzesFetching || isQuizFetching || !userQuizType;

  const showBestMatch = isProductCategoryVeev
    ? quizViewAnswerTags.some(isQuizAnswerTagShowPageBestMatch)
    : quizViewAnswerTags.some(isQuizAnswerTagShowPageBestMatch) &&
      quizViewAnswerTags.some(isQuizAnswerTagProductStateDiscontinued);

  const showBundles =
    quizViewAnswerTags.some(isQuizAnswerTagContextMood) &&
    quizViewAnswerTags.some(isQuizAnswerTagContextCharacterMatch);
  // && quizViewAnswerTags.some(isQuizAnswerTagShowPageBundles); // ! re-enable next release

  const choicesQuantity = {
    maxChoices: quizQuestionNumberOfChoices[quizViewQuestionId]?.max_choices,
    minChoices: quizQuestionNumberOfChoices[quizViewQuestionId]?.min_choices,
  };

  const isLight = isProductCategoryVeev;
  const isBackgroundTint = isProductCategoryVeev;
  const currentPrimaryColor = checkCurrentPrimaryColor();
  const currentEssences = essenceGroupsData?.find(isCurrentEssenceGroup)?.essences;
  const aromaEssence = currentEssences?.find(isEssenceAroma);
  const intensityEssence = currentEssences?.find(isEssenceIntensity);

  const questionText = quizViewData?.text || '';
  const questionTextSplit = questionText.split('\n');
  const questionTitle = questionTextSplit[0];
  const questionCaption = questionTextSplit[1] || '';

  const spinnerComponent = isDesignSetPmiV2 ? (
    <LoadingPage
      currentPrimaryColor={currentPrimaryColor}
      isHeetsBackgroundEnabled={isProductCategoryHeets}
      isShowBeforeResult={isQuizViewCompleted}
    />
  ) : (
    <Spinner />
  );

  // ! Improve
  const quizProps = {
    handleGoBack: handleQuizViewGoBack,
    isFirstQuestion: Object.keys(quizViewUserPath).length === 1,
    isLayoutRightToLeft,
    isQuizzesFetching,
    isUserQuizComplete,
    questionImage: userQuizQuestionImages[quizViewQuestionId],
    quizViewData,
    quizViewProgress,
  };

  const handleOnAnswerSelect = (answerIds, callback?) => {
    window.scrollTo(0, 0);
    handleQuizViewSubmitAnswer(Array.isArray(answerIds) ? answerIds : [answerIds]);
    if (callback) callback();
  };

  /**
   * * Mount
   */
  useEffect(() => {
    if (isRetailerLocationStoreTypeEcommerce) {
      setTimeout(() => {
        setIsEcommerceQuizLoaded(true);
      }, 1500);
    }

    if (!isQuizViewPaused) {
      dispatch(actionResetProductSets());
      dispatch(actionResetProductFeedback());
    }
  }, []);

  /**
   * * Progress bar and reference card states
   */
  useEffect(() => {
    setQuizViewProgress(QuizUtils.getProgressPercentage());
    setQuizViewReferenceProduct(QuizUtils.getReferenceProductData());
  }, [quizViewQuestionId]);

  /**
   * * Go to result page when quiz is completed
   */
  useEffectSkipFirst(() => {
    if (isUserQuizComplete) navigate(prependBasename(PAGES.ta.result));
  }, [isUserQuizComplete]);

  useEffectSkipFirst(() => {
    if (remoteAccessKey && !isRemoteAccessValid) {
      navigate(prependBasename(PAGES.error.forbidden));
    }
  }, [isRemoteAccessValid]);

  /**
   * * Reference product card data
   */
  useEffect(() => {
    if (quizViewReferenceProduct && productsList.length) {
      const answerProduct = Object.values(quizViewReferenceProduct)[0];
      const selectedProductTags = answerProduct?.tags || [];
      const referenceProductSlug = selectedProductTags.find(isQuizAnswerTagContextReferenceProduct);

      if (referenceProductSlug) {
        const finalGprl = productsList.find(gprl => isGprlReferenceProduct(gprl, referenceProductSlug));

        if (finalGprl) {
          setSelectedProduct(finalGprl.product);
          setSelectedAnswerProduct(answerProduct);
        } else {
          setSelectedProduct(null);
          setSelectedAnswerProduct(answerProduct);
        }
      }
    }
  }, [quizViewReferenceProduct, productsList]);

  /**
   * * Trigger conditions for product pages when `quizViewAnswerTags` are changed.
   */
  useEffectSkipFirst(() => {
    const isFirstVisit =
      !quizViewPausedQuestionId ||
      (quizViewPausedQuestionId && !JSON.stringify(quizViewUserPath).includes(quizViewPausedQuestionId));
    const isRevisit = quizViewPausedQuestionId === quizViewPreviousQuestionId;
    const shouldPauseQuiz = !isQuizViewPaused && (isFirstVisit || isRevisit);

    if (shouldPauseQuiz && (showBestMatch || showBundles)) {
      dispatch(actionPauseQuizView());
    }
  }, [showBestMatch, showBundles, isQuizViewBackward]);

  useEffectSkipFirst(() => {
    if (!isQuizViewPaused) return;

    if (showBestMatch) {
      navigate(prependBasename(PAGES.ta.products.bestMatch));
    }

    if (showBundles) {
      navigate(prependBasename(PAGES.ta.products.bundles));
    }
  }, [isQuizViewPaused]);

  useLayoutEffect(() => {
    broadcastDomHeightToHost();
  }, [isFetching, quizViewQuestionId, isUserQuizComplete, isEcommerceQuizLoaded]);

  switch (quizQuestionDesignSets[quizViewQuestionId]) {
    case 'QDS8001':
      renderComponent = (
        <QuizPageQDS8001
          answers={quizProps.quizViewData?.answers}
          choicesQuantity={choicesQuantity}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLight={isLight}
          isLoading={false}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
          selectedChoicesCount={1}
        />
      );
      break;

    case 'QDS8002':
      renderComponent = (
        <QuizPageQDS8002
          answers={quizProps.quizViewData?.answers}
          choicesQuantity={choicesQuantity}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLight={isLight}
          isLoading={false}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
        />
      );
      break;

    case 'QDS8003':
      renderComponent = productsList.length ? (
        <QuizPageQDS8003
          productCardShowEssenceLink
          answers={quizProps.quizViewData?.answers}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLoading={false}
          isPearlProduct={isProductPearl(selectedProduct)}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
          productCardCaption={selectedProduct?.caption || ''}
          productCardEssenceDescription={intensityEssence?.description}
          productCardImage={selectedAnswerProduct?.image}
          productCardName={selectedProduct?.name || ''}
          productCardPrimaryColor={selectedProduct?.attributes.color || selectedProduct?.attributes.color_primary || ''}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
        />
      ) : (
        spinnerComponent
      );
      break;
    case 'QDS8004':
      renderComponent = productsList.length ? (
        <QuizPageQDS8004
          productCardShowEssenceLink
          answers={quizProps.quizViewData?.answers}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLoading={false}
          isPearlProduct={isProductPearl(selectedProduct)}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
          productCardCaption={selectedProduct?.caption || ''}
          productCardEssenceDescription={aromaEssence?.description}
          productCardImage={selectedAnswerProduct?.image}
          productCardName={selectedProduct?.name || ''}
          productCardPrimaryColor={selectedProduct?.attributes.color || selectedProduct?.attributes.color_primary || ''}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
        />
      ) : (
        spinnerComponent
      );
      break;

    case 'QDS8005':
      renderComponent = (
        <QuizPageQDS8005
          answers={quizProps.quizViewData?.answers}
          choicesQuantity={choicesQuantity}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLight={isLight}
          isLoading={false}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
          selectedChoicesCount={1}
        />
      );
      break;

    case 'QDS8006':
      renderComponent = (
        <QuizPageQDS8006
          answers={quizProps.quizViewData?.answers}
          currentPrimaryColor={currentPrimaryColor}
          isBackgroundTint={isBackgroundTint}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isLight={isLight}
          isShowRestrictedBackground={isShowRestrictedBackground}
          isVeev={isProductCategoryVeev}
          noOfAnswersLeft={quizViewDepth}
          progressPercent={quizViewProgress}
          question={{ caption: questionCaption, title: questionTitle }}
          questionImage={quizProps.questionImage}
          onAnswerSelect={handleOnAnswerSelect}
        />
      );
      break;

    case 'QDS8007':
      renderComponent = (
        <QuizPageQDS8007
          answers={quizProps.quizViewData?.answers}
          choicesQuantity={choicesQuantity}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLight={isLight}
          isLoading={false}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
        />
      );
      break;

    case 'QDS8008':
      renderComponent = productsList.length ? (
        <QuizPageQDS8008
          answers={quizProps.quizViewData?.answers}
          choicesQuantity={choicesQuantity}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLight={isLight}
          isLoading={false}
          isPearlProduct={isProductPearl(selectedProduct)}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          isTobaccoExperienceHiddenAddon={isTobaccoExperienceHiddenAddon}
          isVeev={isProductCategoryVeev}
          productCardCaption={selectedProduct?.caption || ''}
          productCardEssenceDescription=""
          productCardImage={selectedAnswerProduct?.image}
          productCardName={selectedProduct?.name || ''}
          productCardPrimaryColor={selectedProduct?.attributes.color || selectedProduct?.attributes.color_primary || ''}
          productCardShowEssenceLink={false}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
          selectedChoicesCount={2}
        />
      ) : (
        spinnerComponent
      );
      break;

    case 'QDS8009':
      renderComponent = (
        <QuizPageQDS8009
          answers={quizProps.quizViewData?.answers}
          choicesQuantity={choicesQuantity}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isIgnoreProductSeparationAddon={isIgnoreProductSeparationAddon}
          isLight={isLight}
          isLoading={false}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
        />
      );
      break;

    case 'QDS8010':
      renderComponent = (
        <QuizPageQDS8010
          answers={quizProps.quizViewData?.answers}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLoading={false}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
        />
      );
      break;

    case 'QDS8011':
      renderComponent = (
        <QuizPageQDS8011
          answers={quizProps.quizViewData?.answers}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isLoading={false}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
        />
      );
      break;

    case 'QDS8012':
      renderComponent = (
        <QuizPageQDS8012
          answers={quizProps.quizViewData?.answers}
          choicesQuantity={choicesQuantity}
          currentPrimaryColor={currentPrimaryColor}
          goBack={quizProps.handleGoBack}
          handleConfirmAnswer={handleOnAnswerSelect}
          isHealthWarningFooterToIsraelAddon={isHealthWarningFooterToIsraelAddon}
          isHealthWarningQuizAddon={isHealthWarningQuizAddon}
          isHeetsBackgroundEnabled={isProductCategoryHeets}
          isLight={isLight}
          isLoading={false}
          isShowBackButton={!quizProps.isFirstQuestion}
          isShowRestrictedBackground={isShowRestrictedBackground}
          progressPercent={quizViewProgress}
          questionCaption={questionCaption}
          questionImage={quizProps.questionImage}
          questionTitle={questionTitle}
        />
      );
      break;

    default:
      renderComponent = spinnerComponent;
  }

  return isFetching ||
    isUserQuizComplete ||
    isQuizViewPaused ||
    !quizViewData ||
    (isRetailerLocationStoreTypeEcommerce && !isEcommerceQuizLoaded)
    ? spinnerComponent
    : renderComponent;
};

export default QuizLauncher(QuizContainer);

import { useSelector } from 'react-redux';

import {
  selectProductsCharacterCorrelationList,
  selectProductsIsLoading,
  selectProductsList,
} from '@lib/core/products/selectors/products';
import { TProductInstance } from '@lib/core/products/types';

type IProducts = {
  isProductsLoading: boolean;
  productsList: TProductInstance[];
  productsByCharacterCorrelation: TProductInstance[];
};
// ToDo rename file
export const useProducts = (): IProducts => ({
  isProductsLoading: useSelector(selectProductsIsLoading),
  productsByCharacterCorrelation: useSelector(selectProductsCharacterCorrelationList),
  productsList: useSelector(selectProductsList),
});

import { TProductInstance } from '@lib/core/products/types';
import { TQuizAnswerTag } from '@lib/core/quizzes/types';
import {
  isAssociatedTereaProduct,
  isProductDimensions,
  isProductLevia,
  isProductSentia,
} from '@lib/tools/shared/pmi/products/filters';
import {
  isQuizAnswerTagContextBestMatch,
  isQuizAnswerTagDissatisfied,
  isQuizAnswerTagLanu,
  isQuizAnswerTagLauHeets,
  isQuizAnswerTagLauSentia,
  isQuizAnswerTagLauTerea,
  isQuizAnswerTagRecommendSentia,
  isQuizAnswerTagSatisfied,
} from '@lib/tools/shared/pmi/quizzes/filters';

export const recommendTerea = (gprls: TProductInstance[], answerTags: TQuizAnswerTag[]): TProductInstance[] => {
  /** Logic for dynamic result page for IQOS4 - https://project.vinhood.com/w/application/iqos4/#logic
   * If the user is "happy" with a product, we need to show that product as the first recommendation.
   * If the user is "unhappy" with a product, we need to show its improved version as the second recommendation.
   */

  const isGprlBestMatch = (gprl: TProductInstance): boolean => {
    return gprl.product.slug === answerTags.find(isQuizAnswerTagContextBestMatch)?.name;
  };
  const isSentiaAvailable = gprls.some(isProductSentia);

  let tereaProducts = gprls.filter(gprl => !isProductSentia(gprl) && !isProductDimensions(gprl));
  const tereaProductsWithSingleLevia = [];

  tereaProducts.forEach(gprl => {
    if (isProductLevia(gprl)) {
      if (!tereaProductsWithSingleLevia.some(isProductLevia)) tereaProductsWithSingleLevia.push(gprl);
    } else {
      tereaProductsWithSingleLevia.push(gprl);
    }
  });

  tereaProducts = tereaProductsWithSingleLevia;

  const sentiaProducts = gprls.filter(isProductSentia);
  const dimensionsProducts = gprls.filter(isProductDimensions);

  const isDimensionsAvailable = dimensionsProducts.length;

  const sentiaBestMatch = sentiaProducts.find(isGprlBestMatch);
  const tereaBestMatch = sentiaBestMatch
    ? tereaProducts.find(gprl => isAssociatedTereaProduct(gprl, sentiaBestMatch))
    : tereaProducts.find(isGprlBestMatch) || dimensionsProducts.find(isGprlBestMatch);

  const tereaProductsRemoveSentiaTereaAssociation = tereaProducts.filter(
    gprl => gprl.product.identifier !== sentiaBestMatch?.product?.attributes?.sentia_terea_association,
  );

  const tereaProductOne =
    answerTags.some(isQuizAnswerTagLanu) && answerTags.some(isQuizAnswerTagRecommendSentia)
      ? tereaProductsRemoveSentiaTereaAssociation[0]
      : tereaProducts[0];
  const tereaProductTwo = tereaProducts[1];
  const sentiaProductOne = sentiaProducts[0];
  const sentiaProductTwo = sentiaProducts[1];
  const sentiaProductThree = sentiaProducts[2];
  const dimensionsProduct = dimensionsProducts[0];

  const sentiaAlternate =
    sentiaBestMatch?.product.slug === sentiaProductOne?.product.slug ? sentiaProductTwo : sentiaProductOne;
  const tereaAlternate =
    tereaBestMatch?.product.slug === tereaProductOne?.product.slug ? tereaProductTwo : tereaProductOne;

  if (
    answerTags.some(isQuizAnswerTagLauHeets) ||
    answerTags.some(isQuizAnswerTagLauTerea) ||
    answerTags.some(isQuizAnswerTagLauSentia)
  ) {
    if (tereaBestMatch) {
      if (sentiaBestMatch) {
        if (answerTags.some(isQuizAnswerTagSatisfied)) return [sentiaBestMatch, sentiaAlternate, tereaBestMatch];
        if (answerTags.some(isQuizAnswerTagDissatisfied)) return [sentiaAlternate, sentiaBestMatch, tereaProductOne];
      } else if (isDimensionsAvailable) {
        // Handle case if selected product is a Dimensions product.
        if (isProductDimensions(tereaBestMatch)) return [tereaProductOne, tereaProductTwo, tereaBestMatch];
        if (answerTags.some(isQuizAnswerTagSatisfied)) return [tereaBestMatch, tereaAlternate, dimensionsProduct];
        if (answerTags.some(isQuizAnswerTagDissatisfied)) return [tereaAlternate, tereaBestMatch, dimensionsProduct];
      } else {
        if (answerTags.some(isQuizAnswerTagSatisfied)) return [tereaBestMatch, tereaAlternate];
        if (answerTags.some(isQuizAnswerTagDissatisfied)) return [tereaAlternate, tereaBestMatch];
      }
    } else if (sentiaBestMatch) {
      // Corner case when the Terea product is missing from the Sentia-Terea lookup table.
      return [
        sentiaProductOne,
        sentiaProductOne?.product.slug === sentiaProductTwo?.product.slug ? sentiaProductThree : sentiaProductTwo,
        tereaProductOne,
      ];
    }
  }

  if (isSentiaAvailable && answerTags.some(isQuizAnswerTagRecommendSentia)) {
    return [tereaProductOne, sentiaProductOne];
  }

  if (isDimensionsAvailable) {
    // LAS - with dimensions
    return [tereaProductOne, tereaProductTwo, dimensionsProduct];
  }

  // Standard LAS
  return tereaProducts.slice(0, 2);
};
